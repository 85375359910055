import React from 'react';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import Moment from 'moment';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

// import roboto from "assets/roboto/Roboto-Regular.ttf";

import RobotoBold from "assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";

import moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import "./styles.css";
import PrintIcon from '@material-ui/icons/Print';
import Button from "components/CustomButtons/Button.js";
import squareBullet from "../../assets/img/squarebullet.png";
import bluerightarrow from "../../assets/img/bluerightarrow.png";
import arrowupgreen from "../../assets/img/arrowgreen.png";
import arrowdownred from "../../assets/img/arowred.png";
import arroworange from "../../assets/img/arroworange.png";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from '@material-ui/core';
import Chart from "react-apexcharts";

// Create styles
const styles = {
 
};
const useStyles = makeStyles(styles);

const PdfMonthlyReport = ({generateMonthlyPdfReport,tmGenerateMonthlyPdfReportRequest,tmGenerateMonthlyOrgDetailsPdfDownloadRequest
}) =>{


   const classes = useStyles();
    const location = useLocation();
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [chartLabels,setChartLabels] = React.useState([]);
  const [chartData,setChartData] = React.useState([]);


  const [dataStatus, setDataStatus] = React.useState(false);
  const [showFundScoreList, setShowFundScoreList] = React.useState(false);
  const [showFundPortfolioSqftList, setShowFundPortfolioSqftList] = React.useState(false);
  const [showTopPerformingTenantsList,setShowTopPerformingTenantsList] = React.useState(false);
  const [showTopPerformingPropertiesList,setShowTopPerformingPropertiesList] = React.useState(false);
  const [showTopPerformingIndustriesList,setShowTopPerformingIndustriesList] = React.useState(false);
  const [showTopPerformingFundsList,setShowTopPerformingFundsList] = React.useState(false);

  const [showFundDetailsList,setShowFundDetailsList] = React.useState(false);
  const [noDataAvailableTopPerformingTenantsList,setNoDataAvailableTopPerformingTenantsList] = React.useState(false);
  const [noDataAvailableTopPerformingPropertiesList,setNoDataAvailableTopPerformingPropertiesList] = React.useState(false);
  const [noDataAvailableTopPerformingIndustyList,setNoDataAvailableTopPerformingIndustyList] = React.useState(false);
  const [noDataAvailableTopPerformingFundsList,setNoDataAvailableTopPerformingFundsList] = React.useState(false);


  const [noDataAvailableFundScoreList,setNoDataAvailableFundScoreList] = React.useState(false);
  const [noDataAvailableFundPortfolioSqftList,setNoDataAvailableFundPortfolioSqftList] = React.useState(false);
  const [noDataAvailableSuits,setNoDataAvailableSuits] = React.useState(false);
  const [showPieChart,setShowPieChart]=React.useState(false);

  const [noDataAvailableInactiveTenantList,setNoDataAvailableInactiveTenantList] = React.useState(false);
  const [noDataAvailablePositiveAlertsTenantList,setNoDataAvailablePositiveAlertsTenantList] = React.useState(false);
  const [noDataAvailableNegativeAlertsTenantList,setNoDataAvailableNegativeAlertsTenantList] = React.useState(false);
  const [noDataAvailableFundTopPerformingProperties,setNoDataAvailableFundTopPerformingProperties] = React.useState(false);
  const [noDataAvailableFundTopPerformingTenants,setNoDataAvailableFundTopPerformingTenants] = React.useState(false);
  const [noDataAvailableFundTopPerformingIndustries,setNoDataAvailableFundTopPerformingIndustries] = React.useState(false);
  const [noDataAvailableIncreasedCreditScoreList,setNoDataAvailableIncreasedCreditScoreList] = React.useState(false);
  const [noDataAvailableDecreasedCreditScoreList,setNoDataAvailableDecreasedCreditScoreList] = React.useState(false);

  const [showInactiveTenantList, setShowInactiveTenantList] = React.useState(false);
  const [showPositiveAlertsTenantList,setShowPositiveAlertsTenantList] = React.useState(false);
  const [showNegativeAlertsTenantList,setShowNegativeAlertsTenantList] = React.useState(false);
  const [showFundTopPerformingProperties,setShowFundTopPerformingProperties] = React.useState(false);
  const [showFundTopPerformingTenants,setShowFundTopPerformingTenants] = React.useState(false);
  const [showFundTopPerformingIndustries,setShowFundTopPerformingIndustries] = React.useState(false);

  const [showFundLowestScoringTable,setShowFundLowestScoringTable] = React.useState(false);
  const [noDataAvailableFundLowestScoringTable,setNoDataAvailableFundLowestScoringTable] = React.useState(false);
  const [showIncrementedCreditScoreList, setShowIncrementedCreditScoreList] = React.useState(false);
  const [showDecrementedCreditScoreList, setShowDecrementedCreditScoreList] = React.useState(false);

  const [showLowRiskCountPercentage, setShowLowRiskCountPercentage] = React.useState(false);
  const [showMediumLowRiskCountPercentage, setShowMediumLowRiskCountPercentage] = React.useState(false);
  const [showModerateHighRiskCountPercentage, setShowModerateHighRiskCountPercentage] = React.useState(false);
  const [showMediumHighRiskCountPercentage, setShowMediumHighRiskCountPercentage] = React.useState(false);
  const [showHighRiskCountPercentage, setShowHighRiskCountPercentage] = React.useState(false);

  const [noDataAvailableLowestScoringProperties,setNoDataAvailableLowestScoringProperties] = React.useState(false);
  const [noDataAvailableLowestScoringTenants,setNoDataAvailableLowestScoringTenants] = React.useState(false);
  const [noDataAvailableLowestScoringIndustries,setNoDataAvailableLowestScoringIndustries] = React.useState(false);
  const [showLowestScoringProperties,setShowLowestScoringProperties] = React.useState(false);
  const [showLowestScoringTenants,setShowLowestScoringTenants] = React.useState(false);
  const [showLowestScoringIndustries,setShowLowestScoringIndustries] = React.useState(false);
  const [showCautinaryUccList,setShowCautinaryUccList] = React.useState(false);
  const [noDataAvailableCautionaryUccList,setNoDataAvailableCautionaryUccList] = React.useState(false);

  const [showTaxLienList,setShowTaxLienList] = React.useState(false);
  const [noDataAvailableTaxLienList,setNoDataAvailableTaxLienList] = React.useState(false);

  const [showJudgementList,setShowJudgementList] = React.useState(false);
  const [noDataAvailableJudgementList,setNoDataAvailableJudgementList] = React.useState(false);

  const [showSuitsList,setShowSuitsList] = React.useState(false);
  const [noDataAvailableSuitsList,setNoDataAvailableSuitsList] = React.useState(false);

  const [showNewCautinaryUccList,setShowNewCautinaryUccList] = React.useState(false);
  const [noDataAvailableNewCautionaryUccList,setNoDataAvailableNewCautionaryUccList] = React.useState(false);

  const [showNewTaxLienList,setShowNewTaxLienList] = React.useState(false);
  const [noDataAvailableNewTaxLienList,setNoDataAvailableNewTaxLienList] = React.useState(false);

  const [showNewJudgementList,setShowNewJudgementList] = React.useState(false);
  const [noDataAvailableNewJudgementList,setNoDataAvailableNewJudgementList] = React.useState(false);

  const [showNewSuitsList,setShowNewSuitsList] = React.useState(false);
  const [noDataAvailableNewSuitsList,setNoDataAvailableNewSuitsList] = React.useState(false);

  const [showNoNewDerogFilings, setShowNoNewDerogFilings] = React.useState(false);
  const [showNewDerogFilingsCount, setShowNewDerogFilingsCount] = React.useState(false);
  const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));
  

const[loader,setLoader]=React.useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const todayNow = moment().format("MMMM YYYY"); 
  var currentYear  = moment().format('YYYY');

  const[showArepLogo,setShowArepLogo]=React.useState(false);
  const[showCarterLogo,setShowCarterLogo]=React.useState(false);
  const[organisationId,setOrganisationId]=React.useState(location.state.organisationId);

React.useEffect(() => {
    
    getMonthlyPdfReports(organisationId);
  // if(generateMonthlyPdfReport!=""){
  //   setLoader(false);
  
  if(organisationId=="60f7cf3a09b25f474b336f8b"){
   setShowArepLogo(true);
   setShowCarterLogo(false);
  }else if(organisationId=="654b476a423c6401f20f16db"){
    setShowArepLogo(false);
    setShowCarterLogo(true);
  }

  setShowPieChart(true);

  setChartLabels(generateMonthlyPdfReport?.riskCounts?.labels);
  setChartData(generateMonthlyPdfReport?.riskCounts?.riskCounts);

  if(generateMonthlyPdfReport?.lowRiskCountPercentage > 0){
    setShowLowRiskCountPercentage(true);
  }else{
    setShowLowRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport?.mediumLowRiskCountPercentage > 0){
    setShowMediumLowRiskCountPercentage(true);
  }else{
    setShowMediumLowRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport?.moderateHighRiskCountPercentage > 0){
    setShowModerateHighRiskCountPercentage(true);
  }else{
    setShowModerateHighRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport?.mediumHighRiskCountPercentage > 0){
    setShowMediumHighRiskCountPercentage(true);
  }else{
    setShowMediumHighRiskCountPercentage(false);
  }

  if(generateMonthlyPdfReport?.highRiskCountPercentage > 0){
    setShowHighRiskCountPercentage(true);
  }else{
    setShowHighRiskCountPercentage(false);
  }
  
  //topPerforming

  if(generateMonthlyPdfReport?.topPerformingFundsList!==null || generateMonthlyPdfReport?.topPerformingFundsList!=[]){
    setShowTopPerformingFundsList(true);
    setNoDataAvailableTopPerformingFundsList(false);
  }

  if(generateMonthlyPdfReport?.topPerformingFundsList?.length===0 || generateMonthlyPdfReport?.topPerformingFundsList===null || generateMonthlyPdfReport?.topPerformingFundsList==[ ]){
    setShowTopPerformingFundsList(false);
    setNoDataAvailableTopPerformingFundsList(true);
  }

  if(generateMonthlyPdfReport?.topPerformingTenantsList!==null || generateMonthlyPdfReport?.topPerformingTenantsList!=[]){
      setShowTopPerformingTenantsList(true);
      setNoDataAvailableTopPerformingTenantsList(false);
    }

    if(generateMonthlyPdfReport?.topPerformingTenantsList?.length===0 || generateMonthlyPdfReport?.topPerformingTenantsList===null || generateMonthlyPdfReport?.topPerformingTenantsList==[ ]){
      setShowTopPerformingTenantsList(false);
      setNoDataAvailableTopPerformingTenantsList(true);
    }
    
  if(generateMonthlyPdfReport?.topPerformingPropertiesList!==null || generateMonthlyPdfReport?.topPerformingPropertiesList!=[ ]){
      setShowTopPerformingPropertiesList(true);
      setNoDataAvailableTopPerformingPropertiesList(false);
    }
    if(generateMonthlyPdfReport?.topPerformingPropertiesList?.length===0 || generateMonthlyPdfReport?.topPerformingPropertiesList==null || generateMonthlyPdfReport?.topPerformingPropertiesList==[ ] || generateMonthlyPdfReport?.topPerformingPropertiesList?.length === 0){
      setShowTopPerformingPropertiesList(false);
      setNoDataAvailableTopPerformingPropertiesList(true);
    }    
   
  if(generateMonthlyPdfReport?.topPerformingIndustriesList!==null || generateMonthlyPdfReport?.topPerformingIndustriesList!=[]){

    setShowTopPerformingIndustriesList(true);
    setNoDataAvailableTopPerformingIndustyList(false);

    }
    if(generateMonthlyPdfReport?.topPerformingIndustriesList?.length===0 || generateMonthlyPdfReport?.topPerformingIndustriesList===null || generateMonthlyPdfReport?.topPerformingIndustriesList==[]){
      setShowTopPerformingIndustriesList(false);
      setNoDataAvailableTopPerformingIndustyList(true);
    }
    
   //positive and negative news list
    if(generateMonthlyPdfReport?.negativeNewsList!==null || generateMonthlyPdfReport?.negativeNewsList!=[ ]){
      setShowNegativeAlertsTenantList(true);
      setNoDataAvailableNegativeAlertsTenantList(false);
    }
    if(generateMonthlyPdfReport?.negativeNewsList?.length===0 || generateMonthlyPdfReport?.negativeNewsList===null || generateMonthlyPdfReport?.negativeNewsList==[ ]){
      setShowNegativeAlertsTenantList(false);
      setNoDataAvailableNegativeAlertsTenantList(true);
    }

    if(generateMonthlyPdfReport?.positiveNewsList!==null || generateMonthlyPdfReport?.positiveNewsList!=[ ]){
      setShowPositiveAlertsTenantList(true);
      setNoDataAvailablePositiveAlertsTenantList(false);
    }
    if(generateMonthlyPdfReport?.positiveNewsList?.length===0 || generateMonthlyPdfReport?.positiveNewsList===null || generateMonthlyPdfReport?.positiveNewsList==[ ]){
      setShowPositiveAlertsTenantList(false);
      setNoDataAvailablePositiveAlertsTenantList(true);
    }

    // creditscore incremented and decremented list
    if(generateMonthlyPdfReport?.creditScoreIncrementedList!==null || generateMonthlyPdfReport?.creditScoreIncrementedList!=[ ]){
      setShowIncrementedCreditScoreList(true);
      setNoDataAvailableIncreasedCreditScoreList(false);
    }
    if(generateMonthlyPdfReport?.creditScoreIncrementedList?.length===0 || generateMonthlyPdfReport?.creditScoreIncrementedList===null || generateMonthlyPdfReport?.creditScoreIncrementedList==[ ]){
      setShowIncrementedCreditScoreList(false);
      setNoDataAvailableIncreasedCreditScoreList(true);
    }

    if(generateMonthlyPdfReport?.creditScoreDecrementedList!=null || generateMonthlyPdfReport?.creditScoreDecrementedList!=[ ]){
      setShowDecrementedCreditScoreList(true);
      setNoDataAvailableDecreasedCreditScoreList(false);
    }
    if(generateMonthlyPdfReport?.creditScoreDecrementedList?.length===0|| generateMonthlyPdfReport?.creditScoreDecrementedList==null || generateMonthlyPdfReport?.creditScoreDecrementedList==[ ]){
      setShowDecrementedCreditScoreList(false);
      setNoDataAvailableDecreasedCreditScoreList(true);
    }


    //lowestScoring
    if(generateMonthlyPdfReport?.lowestScoringPropertiesList?.length === 0){
      setShowLowestScoringProperties(false);
      setNoDataAvailableLowestScoringProperties(true);
   
    }
    if(generateMonthlyPdfReport?.lowestScoringPropertiesList?.length > 0){
      setShowLowestScoringProperties(true);
      setNoDataAvailableLowestScoringProperties(false);
    }
  
    if(generateMonthlyPdfReport?.lowestScoringTenantsList?.length > 0){
      setShowLowestScoringTenants(true);
      setNoDataAvailableLowestScoringTenants(false);
    
    }
    if(generateMonthlyPdfReport?.lowestScoringTenantsList?.length === 0){
      setShowLowestScoringTenants(false);
      setNoDataAvailableLowestScoringTenants(true);
    }
    
    if(generateMonthlyPdfReport?.lowestScoringIndustriesList?.length > 0){
      setShowLowestScoringIndustries(true);
      setNoDataAvailableLowestScoringIndustries(false);
     
    }
    if(generateMonthlyPdfReport?.lowestScoringIndustriesList?.length === 0){
      setShowLowestScoringIndustries(false);
      setNoDataAvailableLowestScoringIndustries(true);
    }

   //cautionaryUcclist
if(generateMonthlyPdfReport?.cautionaryUccFundDTOList?.length===0){
  setShowCautinaryUccList(false);
  // setNoDataAvailableCautionaryUccList(true);
}else{
  setShowCautinaryUccList(true);
  setNoDataAvailableCautionaryUccList(false);
}
 

  if(generateMonthlyPdfReport?.taxLiensFundDTOList?.length===0){
     
    setShowTaxLienList(false);
      // setNoDataAvailableTaxLienList(true);
    }else{
      setShowTaxLienList(true);
      setNoDataAvailableTaxLienList(false);
    }
   

    if(generateMonthlyPdfReport?.judgementFundDTOList?.length===0){

        setShowJudgementList(false);
        // setNoDataAvailableJudgementList(true);
    }else{
      setShowJudgementList(true);
      setNoDataAvailableJudgementList(false);
    }
     
      if(generateMonthlyPdfReport?.suitsFundDTOList?.length===0){
         
          setShowSuitsList(false);
          // setNoDataAvailableSuitsList(true);
      
        }else{
          setShowSuitsList(true);
          setNoDataAvailableSuitsList(false);
        }

        if(generateMonthlyPdfReport?.cautionaryUccFundDTOList?.length===0 && generateMonthlyPdfReport?.taxLiensFundDTOList?.length===0 && generateMonthlyPdfReport?.judgementFundDTOList?.length===0 && generateMonthlyPdfReport?.suitsFundDTOList?.length===0){
          setShowSuitsList(false);
          setShowJudgementList(false);
          setShowTaxLienList(false);
          setShowCautinaryUccList(false);

        }else{
          // setShowNoNewDerogFilings(false);
          // setShowNewDerogFilingsCount(true);
        }
     
        //new derog filings
        if(generateMonthlyPdfReport?.newCautionaryUccFundDTOList?.length===0){     
       
          setShowNewCautinaryUccList(false);
          // setNoDataAvailableNewCautionaryUccList(true);
          // setShowNoNewDerogFilings(true);
      
          }
          else{
       
            setShowNewCautinaryUccList(true);
            setNoDataAvailableNewCautionaryUccList(false);
            setShowNoNewDerogFilings(false);
          }
            
        
          if(generateMonthlyPdfReport?.newTaxLiensFundDTOList?.length===0){    
        
            setShowNewTaxLienList(false);
            // setNoDataAvailableNewTaxLienList(true);
            // setShowNoNewDerogFilings(true);   
       
            }else{
              setShowNewTaxLienList(true);
              setNoDataAvailableNewTaxLienList(false);
              setShowNoNewDerogFilings(false);
            }
          
        
            if(generateMonthlyPdfReport?.newJudgementFundDTOList?.length===0){
    
              setShowNewJudgementList(false);
              // setNoDataAvailableNewJudgementList(true);
              // setShowNoNewDerogFilings(true);

              }else{
              setShowNewJudgementList(true);
              setNoDataAvailableNewJudgementList(false);
              setShowNoNewDerogFilings(false);
            
              }
              
              if(generateMonthlyPdfReport?.newSuitsFundDTOList?.length===0){
          
                setShowNewSuitsList(false);
                // setNoDataAvailableNewSuitsList(true);
                // setShowNoNewDerogFilings(true);
              
                }else{
                    
                setShowNewSuitsList(true);
                setNoDataAvailableNewSuitsList(false);
                setShowNoNewDerogFilings(false);
                }

                if(generateMonthlyPdfReport?.newCautionaryUccFundDTOList?.length===0 && generateMonthlyPdfReport?.newTaxLiensFundDTOList?.length===0 && generateMonthlyPdfReport?.newJudgementFundDTOList?.length===0 && generateMonthlyPdfReport?.newSuitsFundDTOList?.length===0){
                  setShowNoNewDerogFilings(true);
                  setShowNewDerogFilingsCount(false);
                  setShowNewSuitsList(false);
                  setShowNewJudgementList(false);
                  setShowNewTaxLienList(false);
                  setShowNewCautinaryUccList(false);

                }else{
                  setShowNoNewDerogFilings(false);
                  setShowNewDerogFilingsCount(true);
                }
              // }
setTimeout(() => {
setDataStatus(true);  
}, 8000);

    }, [dataStatus]);
   

const getMonthlyPdfReports = async (organisationId) => {
await tmGenerateMonthlyPdfReportRequest(organisationId);
};

const chartOptions = {
    options: {
      chart: {
        type: "pie",
      },
      labels: generateMonthlyPdfReport?.riskCounts?.labels,
      legend: {
        position: "bottom",
      },
      colors: ['#4caf50', '#32cd32', '#ffdf00', '#ff9800', 'red'], // Define colors inside options
    },
    series: generateMonthlyPdfReport?.riskCounts?.riskCounts, // ApexCharts expects data in series as an array
  };

    
    const handleClickDownload = (value) => {
console.log("selectedOrganization"+selectedOrganization)
      tmGenerateMonthlyOrgDetailsPdfDownloadRequest(organisationId,selectedOrganization)
      
      };
     
      const toCurrency=(value)=>{
        let number = parseFloat(value);
        return number.toLocaleString('en-US');
      }

if(dataStatus){
return(
<div>
  <Card>
  <div align="right" style={{marginRight: "35px",marginTop:"10px"}}>
    <Button onClick={handleClickDownload}  color="info" size="sm" className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}>
    <PrintIcon/>  &nbsp;&nbsp;Print  
    </Button>
  </div>

<div style={{fontSize:"25px",marginTop:"20px",color:megaColor,fontWeight:"bold",textAlign:"center"}}>Monthly Portfolio Summary</div>
<div style={{fontSize:"25px",marginTop:"20px",color:megaColor,textAlign:"center"}}>{todayNow}</div>

<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}}>            
 <div class="table-container-tenant-summary">
 The Portfolio has an overall score of {generateMonthlyPdfReport?.portfolioScore} (on a scale of 0-500),which is based on a blended weighted average based on overall square footage of the three funds. </div>
</div>

{/* graph */}
<div style={{display:"flex",flexDirection:"row"}}>
  <div class="graph-card">
     <Chart options={chartOptions.options} series={chartOptions.series} type="pie" width={280} height={290} align="center"/>
  </div>
  <div class="arrow">
  <img class="arrow2" src={bluerightarrow} alt="Arrow" />
  </div>
  <div class="graph-card1">
  <p style={{ fontSize: "15px", color: "#000000", marginTop: 5,fontWeight:"bold" }}>The Portfolio has:</p>

        {showLowRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 7 }}>
           <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
            {generateMonthlyPdfReport?.lowRiskCountPercentage}% of its tenants with a Low Risk Score (401 and higher)
            </p>  

            </div>
        )}

        {showMediumLowRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
           <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
            {generateMonthlyPdfReport?.mediumLowRiskCountPercentage}% of the tenants with a Medium Low Risk Score (301 to 400)            </p>  

            </div>
        )}

        {showModerateHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
            <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
            {generateMonthlyPdfReport?.moderateHighRiskCountPercentage}% of the tenants have a Moderately High Risk Score (201 to 300)            </p>  

            </div>
        )}

        {showMediumHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
             <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
            {generateMonthlyPdfReport?.mediumHighRiskCountPercentage}% of the tenants with a Medium High Risk Score (101 to 200)            </p>  

            </div>
        )}
        {showHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
            {generateMonthlyPdfReport?.highRiskCountPercentage}% of the tenants have a very High Risk Score (0 to 100)            </p>  

            </div>
        )}
</div>
</div>

{/* Top performing funds */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
      {/* Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Top Performing Funds</div>
      </header>

      {/* Top Performing Funds Table */}
      {showTopPerformingFundsList && (
        <table className="table-income">
          <thead className="table-income-thead">
            <tr>
              <td>Funds</td>
              <td style={{ textAlign: "center" }}>Fund Score</td>
              <td style={{ textAlign: "center" }}>Portfolio (%)</td>
              <td style={{ textAlign: "center" }}>Monthly Change</td>
            </tr>
          </thead>
          <tbody>
            {generateMonthlyPdfReport?.topPerformingFundsList &&
              generateMonthlyPdfReport?.topPerformingFundsList?.map((topFund, i) => (
                <tr key={i}>
                  <td>{topFund.name}</td>
                  <td style={{ textAlign: "center" }}>{topFund.currentScore}</td>
                  <td style={{ textAlign: "center" }}>{topFund.percentageOfPortfolio}%</td>
                  <td style={{ textAlign: "center" }}>
                    {topFund.monthlyChange === "up" ? (
                      <img src={require("assets/img/arrowgreen.png")} alt="Up" style={{ width: "16px" }} />
                    ) : topFund.monthlyChange === "down" ? (
                      <img src={require("assets/img/arowred.png")} alt="Down" style={{ width: "16px" }} />
                    ) : (
                      <img src={require("assets/img/arroworange.png")} alt="Neutral" style={{ width: "20px" }} />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {/* No Data Available */}
      {noDataAvailableTopPerformingFundsList && (
        <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
          No Data Available for top performing funds...
        </p>
      )}
      
</div>

{/* Top performing properties */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
      {/* Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Top Performing Properties</div>
      </header>

      {/* Top Performing Properties Table */}
      {showTopPerformingPropertiesList && (
        <table className="table-income">
          <thead className="table-income-thead">
            <tr>
              <td>Properties</td>
              <td style={{ textAlign: "center" }}>Property Score</td>
              <td style={{ textAlign: "center" }}>Portfolio (%)</td>
              <td style={{ textAlign: "center" }}>Monthly Change</td>
            </tr>
          </thead>
          <tbody>
            {generateMonthlyPdfReport?.topPerformingPropertiesList &&
              generateMonthlyPdfReport.topPerformingPropertiesList.map((topProperty, i) => (
                <tr key={i}>
                  <td>{topProperty.name}</td>
                  <td style={{ textAlign: "center" }}>{topProperty.currentScore}</td>
                  <td style={{ textAlign: "center" }}>{topProperty.percentageOfPortfolio}%</td>
                  <td style={{ textAlign: "center" }}>
                    {topProperty.monthlyChange === "up" ? (
                      <img src={require("assets/img/arrowgreen.png")} alt="Up" style={{ width: "16px" }} />
                    ) : topProperty.monthlyChange === "down" ? (
                      <img src={require("assets/img/arowred.png")} alt="Down" style={{ width: "16px" }} />
                    ) : (
                      <img src={require("assets/img/arroworange.png")} alt="Neutral" style={{ width: "20px" }} />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {/* No Data Available */}
      {noDataAvailableTopPerformingPropertiesList && (
        <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
          There are no properties which have a score of more than 300 for the current month.
        </p>
      )}
</div>

{/* Lowest Performing Properties ` */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
      {/* Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Lowest Performing Properties</div>
      </header>

      {/* Table */}
      {showLowestScoringProperties && (
        <table className="table-income">
          <thead className="table-income-thead">
            <tr>
              <td>Properties</td>
              <td style={{ textAlign: "center" }}>Property Score</td>
              <td style={{ textAlign: "center" }}>Portfolio (%)</td>
              <td style={{ textAlign: "center" }}>Monthly Change</td>
            </tr>
          </thead>
          <tbody>
            {generateMonthlyPdfReport?.lowestScoringPropertiesList &&
              generateMonthlyPdfReport.lowestScoringPropertiesList.map((property, i) => (
                <tr key={i}>
                  <td>{property.name}</td>
                  <td style={{ textAlign: "center" }}>{property.currentScore}</td>
                  <td style={{ textAlign: "center" }}>{property.percentageOfPortfolio}%</td>
                  <td style={{ textAlign: "center" }}>
                    {property.monthlyChange === "up" ? (
                      <img src={require("assets/img/arrowgreen.png")} alt="Up" style={{ width: "16px" }} />
                    ) : property.monthlyChange === "down" ? (
                      <img src={require("assets/img/arowred.png")} alt="Down" style={{ width: "16px" }} />
                    ) : (
                      <img src={require("assets/img/arroworange.png")} alt="Neutral" style={{ width: "20px" }} />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {/* No Data Available */}
      {noDataAvailableLowestScoringProperties && (
        <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
          There are no properties which have a score of less than 300 for the current month.
        </p>
      )}
</div>

{/* Top performing tenants */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {/* Header */}
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">Top Performing Tenants</div>
  </header>

  {/* Table */}
  {showTopPerformingTenantsList && (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <td>Tenants</td>
          <td style={{ textAlign: "center" }}>Tenant Score</td>
          <td style={{ textAlign: "center" }}>Portfolio (%)</td>
          <td style={{ textAlign: "center" }}>Monthly Change</td>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport?.topPerformingTenantsList &&
          generateMonthlyPdfReport.topPerformingTenantsList.map((tenant, i) => (
            <tr key={i}>
              <td>{tenant.name}</td>
              <td style={{ textAlign: "center" }}>{tenant.currentScore}</td>
              <td style={{ textAlign: "center" }}>{tenant.percentageOfPortfolio}%</td>
              <td style={{ textAlign: "center" }}>
                {tenant.monthlyChange === "up" ? (
                  <img src={require("assets/img/arrowgreen.png")} alt="Up" style={{ width: "16px" }} />
                ) : tenant.monthlyChange === "down" ? (
                  <img src={require("assets/img/arowred.png")} alt="Down" style={{ width: "16px" }} />
                ) : (
                  <img src={require("assets/img/arroworange.png")} alt="Neutral" style={{ width: "20px" }} />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )}

  {/* No Data Available */}
  {noDataAvailableTopPerformingTenantsList && (
    <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
      There are no tenants which have a score of more than 300 for the current month.
    </p>
  )}
</div>

{/* Lowest Performing tenants */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {/* Header */}
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">Lowest Performing Tenants</div>
  </header>

  {/* Table */}
  {showLowestScoringTenants && (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <td>Tenants</td>
          <td style={{ textAlign: "center" }}>Tenant Score</td>
          <td style={{ textAlign: "center" }}>Portfolio (%)</td>
          <td style={{ textAlign: "center" }}>Monthly Change</td>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport?.lowestScoringTenantsList &&
          generateMonthlyPdfReport.lowestScoringTenantsList.map((tenant, i) => (
            <tr key={i}>
              <td>{tenant.name}</td>
              <td style={{ textAlign: "center" }}>{tenant.currentScore}</td>
              <td style={{ textAlign: "center" }}>{tenant.percentageOfPortfolio}%</td>
              <td style={{ textAlign: "center" }}>
                {tenant.monthlyChange === "up" ? (
                  <img src={require("assets/img/arrowgreen.png")} alt="Up" style={{ width: "16px" }} />
                ) : tenant.monthlyChange === "down" ? (
                  <img src={require("assets/img/arowred.png")} alt="Down" style={{ width: "16px" }} />
                ) : (
                  <img src={require("assets/img/arroworange.png")} alt="Neutral" style={{ width: "20px" }} />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )}

  {/* No Data Available */}
  {noDataAvailableLowestScoringTenants && (
    <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
      There are no tenants which have a score of less than 300 for the current month.
    </p>
  )}
</div>

{/* Top performing industries */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {/* Header */}
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">Top Performing Industries</div>
  </header>

  {/* Table */}
  {showTopPerformingIndustriesList && (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <td>Industries</td>
          <td style={{ textAlign: "center" }}>Industry Score</td>
          <td style={{ textAlign: "center" }}>Portfolio (%)</td>
          <td style={{ textAlign: "center" }}>Monthly Change</td>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport?.topPerformingIndustriesList &&
          generateMonthlyPdfReport.topPerformingIndustriesList.map((industry, i) => (
            <tr key={i}>
              <td>{industry.name}</td>
              <td style={{ textAlign: "center" }}>{industry.currentScore}</td>
              <td style={{ textAlign: "center" }}>{industry.percentageOfPortfolio}%</td>
              <td style={{ textAlign: "center" }}>
                {industry.monthlyChange === "up" ? (
                  <img src={require("assets/img/arrowgreen.png")} alt="Up" style={{ width: "16px" }} />
                ) : industry.monthlyChange === "down" ? (
                  <img src={require("assets/img/arowred.png")} alt="Down" style={{ width: "16px" }} />
                ) : (
                  <img src={require("assets/img/arroworange.png")} alt="Neutral" style={{ width: "20px" }} />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )}

  {/* No Data Available */}
  {noDataAvailableTopPerformingIndustyList && (
    <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
      No Data Available for top performing industries...
    </p>
  )}
</div>

{/* Lowest performing industries */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {/* Header */}
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">Lowest Performing Industries</div>
  </header>

  {/* Table */}
  {showLowestScoringIndustries && (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <td>Industries</td>
          <td style={{ textAlign: "center" }}>Industry Score</td>
          <td style={{ textAlign: "center" }}>Portfolio (%)</td>
          <td style={{ textAlign: "center" }}>Monthly Change</td>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport?.lowestScoringIndustriesList &&
          generateMonthlyPdfReport.lowestScoringIndustriesList.map((industry, i) => (
            <tr key={i}>
              <td>{industry.name}</td>
              <td style={{ textAlign: "center" }}>{industry.currentScore}</td>
              <td style={{ textAlign: "center" }}>{industry.percentageOfPortfolio}%</td>
              <td style={{ textAlign: "center" }}>
                {industry.monthlyChange === "up" ? (
                  <img src={require("assets/img/arrowgreen.png")} alt="Up" style={{ width: "16px" }} />
                ) : industry.monthlyChange === "down" ? (
                  <img src={require("assets/img/arowred.png")} alt="Down" style={{ width: "16px" }} />
                ) : (
                  <img src={require("assets/img/arroworange.png")} alt="Neutral" style={{ width: "20px" }} />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )}

  {/* No Data Available */}
  {noDataAvailableLowestScoringIndustries && (
    <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
      No Data Available for lowest scoring industries...
    </p>
  )}
</div>

{/* Credit */}
<div  class="section-content-title-tenantsummary" >
        Credit Score Changes
</div>
<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
<div class="table-container-tenant-summary">{generateMonthlyPdfReport.creditScoreText}</div>
</div>

{/* Increased credit score */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {/* Header */}
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">Tenants with Increased Credit Score</div>
  </header>

  {/* Table */}
  {showIncrementedCreditScoreList && (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <td>Tenants</td>
          <td style={{ textAlign: "center" }}>Previous Score</td>
          <td style={{ textAlign: "center" }}>Current Score</td>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport?.creditScoreIncrementedList &&
          generateMonthlyPdfReport.creditScoreIncrementedList.map((tenant, i) => (
            <tr key={i}>
              <td>{tenant.name}</td>
              <td style={{ textAlign: "center" }}>{tenant.prevScore}</td>
              <td style={{ textAlign: "center" }}>
                {tenant.currScore}&nbsp;({tenant.percentage}%)
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )}

  {/* No Data Available */}
  {noDataAvailableIncreasedCreditScoreList && (
    <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
      There are no tenants which have a credit score that increased by 50 basis points in the current month.
    </p>
  )}
</div>

{/* Decreased Credit score */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {/* Header */}
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">Tenants with Decreased Credit Score</div>
  </header>

  {/* Table */}
  {showDecrementedCreditScoreList && (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <td>Tenants</td>
          <td style={{ textAlign: "center" }}>Previous Score</td>
          <td style={{ textAlign: "center" }}>Current Score</td>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport?.creditScoreDecrementedList &&
          generateMonthlyPdfReport.creditScoreDecrementedList.map((tenant, i) => (
            <tr key={i}>
              <td>{tenant.name}</td>
              <td style={{ textAlign: "center" }}>{tenant.prevScore}</td>
              <td style={{ textAlign: "center" }}>
                {tenant.currScore}&nbsp;({tenant.percentage}%)
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )}

  {/* No Data Available */}
  {noDataAvailableDecreasedCreditScoreList && (
    <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
      There are no tenants which have a credit score that decreased by 50 basis points in the current month.
    </p>
  )}
</div>

{/* Derogatory  */}
<div  class="section-content-title-tenantsummary" >
Derogatory Summary
</div>

{showNoNewDerogFilings &&
<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}}>            
 <div class="table-container-tenant-summary">There are no new derogatory filings for the current month.    </div>
</div>}

{showNewDerogFilingsCount &&<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">{generateMonthlyPdfReport.newDerogText}</div>
</div>}


{/* New Cautionary UCC Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showNewCautinaryUccList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Cautionary UCC Filings</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.newCautionaryUccFundDTOList !== "" &&
        generateMonthlyPdfReport.newCautionaryUccFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.cautionaryUccPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.cautionaryUccAndTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Jurisdiction</th>
                          <th>Secured Party Information</th>
                          <th>Collateral</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through UCC details */}
                        {tenant.cautionaryUccDetailsList.map((details, l) => (
                          <tr key={l}>
                            <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{details.jurisdiction}</td>
                            <td>{details.securedPartyName}%</td>
                            <td>{details.collateral}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* New Tax Lien Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showNewTaxLienList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Tax Lien Filings</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.newTaxLiensFundDTOList !== "" &&
        generateMonthlyPdfReport.newTaxLiensFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.taxLiensPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.taxLiensTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Filing Type</th>
                          <th>Filing Number</th>
                          <th>Amount</th>
                          <th>Jurisdiction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through tax lien details */}
                        {tenant.taxLienDerogDetailsList.map((taxLien, l) => (
                          <tr key={l}>
                            <td>{Moment(taxLien.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{taxLien.filingType}</td>
                            <td>{taxLien.filingNumber}</td>
                            <td>${toCurrency(taxLien.amount)}</td>
                            <td>{taxLien.jurisdiction}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>


{/* New Judgement Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showNewJudgementList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Judgement Filings</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.newJudgementFundDTOList !== "" &&
        generateMonthlyPdfReport.newJudgementFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.judgementPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.judgementTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Filing Type</th>
                          <th>Case Number</th>
                          <th>Amount</th>
                          <th>Plaintiff</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through judgement details */}
                        {tenant.judgementDerogDetailsList.map((judgement, l) => (
                          <tr key={l}>
                            <td>{Moment(judgement.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{judgement.filingType}</td>
                            <td>{judgement.filingNumber}</td>
                            <td>${toCurrency(judgement.amount)}</td>
                            <td>{judgement.plaintiff}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* New Civil Suits */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showNewSuitsList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Civil Suits</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.newSuitsFundDTOList !== "" &&
        generateMonthlyPdfReport.newSuitsFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.suitsPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.suitsTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Filing Type</th>
                          <th>Case Number</th>
                          <th>Amount</th>
                          <th>Plaintiff</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through suit details */}
                        {tenant.suitsDerogDetailsList.map((suit, l) => (
                          <tr key={l}>
                            <td>{Moment(suit.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{suit.filingType}</td>
                            <td>{suit.filingNumber}</td>
                            <td>${toCurrency(suit.amount)}</td>
                            <td>{suit.plaintiff}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">{generateMonthlyPdfReport.derogText}</div>
</div>

{/* Cautionary UCC Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showCautinaryUccList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Cautionary UCC Filings</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.cautionaryUccFundDTOList !== "" &&
        generateMonthlyPdfReport.cautionaryUccFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.cautionaryUccPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.cautionaryUccAndTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Jurisdiction</th>
                          <th>Secured Party Information</th>
                          <th>Collateral</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through UCC details */}
                        {tenant.cautionaryUccDetailsList.map((ucc, l) => (
                          <tr key={l}>
                            <td>{Moment(ucc.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{ucc.jurisdiction}</td>
                            <td>{ucc.securedPartyName}</td>
                            <td>{ucc.collateral}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* Tax Lien Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showTaxLienList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Tax Lien Filings</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.taxLiensFundDTOList !== "" &&
        generateMonthlyPdfReport.taxLiensFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.taxLiensPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.taxLiensTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Filing Type</th>
                          <th>Filing Number</th>
                          <th>Amount</th>
                          <th>Jurisdiction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through tax lien details */}
                        {tenant.taxLienDerogDetailsList.map((lien, l) => (
                          <tr key={l}>
                            <td>{Moment(lien.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{lien.filingType}</td>
                            <td>{lien.filingNumber}</td>
                            <td>{lien.amount}</td>
                            <td>{lien.jurisdiction}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>


{/* Judgment Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showJudgementList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Judgment Filings</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.judgementFundDTOList !== "" &&
        generateMonthlyPdfReport.judgementFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.judgementPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.judgementTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Filing Type</th>
                          <th>Case Number</th>
                          <th>Amount</th>
                          <th>Plaintiff</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through judgment details */}
                        {tenant.judgementDerogDetailsList.map((judgement, l) => (
                          <tr key={l}>
                            <td>{Moment(judgement.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{judgement.filingType}</td>
                            <td>{judgement.filingNumber}</td>
                            <td>{judgement.amount}</td>
                            <td>{judgement.plaintiff}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* Civil Suits */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showSuitsList && (
    <>
      {/* Section Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Civil Suits</div>
      </header>

      {/* Loop through funds */}
      {generateMonthlyPdfReport.suitsFundDTOList !== "" &&
        generateMonthlyPdfReport.suitsFundDTOList.map((fund, i) => (
          <div key={i}>
            {/* Fund Name */}
            <h3
              style={{
                fontSize: "16px",
                color: "#000000",
                marginTop: "20px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Fund: {fund.fundName}
            </h3>

            {/* Loop through properties */}
            {fund.suitsPropertyList.map((property, j) => (
              <div key={j}>
                <h4
                  style={{
                    fontSize: "15px",
                    color: "#000000",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Property: {property.propertyName}
                </h4>

                {/* Loop through tenants */}
                {property.suitsTenantDTOList.map((tenant, k) => (
                  <div key={k}>
                    <h5
                      style={{
                        fontSize: "14px",
                        color: "#000000",
                        marginTop: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Tenant: {tenant.tenantName}
                    </h5>

                    {/* Table */}
                    <table className="table-income">
                      <thead className="table-income-thead">
                        <tr>
                          <th>Date Filed</th>
                          <th>Filing Type</th>
                          <th>Case Number</th>
                          <th>Amount</th>
                          <th>Plaintiff</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through civil suit details */}
                        {tenant.suitsDerogDetailsList.map((suit, l) => (
                          <tr key={l}>
                            <td>{Moment(suit.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{suit.filingType}</td>
                            <td>{suit.filingNumber}</td>
                            <td>{suit.amount}</td>
                            <td>{suit.plaintiff}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* News Summary */}

<div  class="section-content-title-tenantsummary" >
       News Summary
</div>
<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">{generateMonthlyPdfReport.newsText}</div>
</div>

{/* Tenants with Positive News Alerts */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">
      Tenants with Positive News Alerts
    </div>
  </header>

  {showPositiveAlertsTenantList ? (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <th>Name</th>
          <th>Category</th>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport.positiveNewsList !== "" &&
          generateMonthlyPdfReport.positiveNewsList.map((positiveNews, i) => (
            <tr key={i}>
              <td>{positiveNews.tenantName}</td>
              <td>{positiveNews.category.join(", ")}</td>
            </tr>
          ))}
      </tbody>
    </table>
  ) : (
    noDataAvailablePositiveAlertsTenantList && (
      <p
        style={{
          fontSize: "11px",
          marginTop: "20px",
          fontWeight: "900",
          textAlign: "left",
        }}
      >
        No Data Available for positive news alerts...
      </p>
    )
  )}
</div>

{/* Tenants with Negative News Alerts */}
<div style={{ marginLeft: "35px", marginRight: "35px",marginBottom:"20px" }}>
  <header className="locationheader-tenantsummary">
    <div className="locationheadercolor-tenantsummary">
      Tenants with Negative News Alerts
    </div>
  </header>

  {showNegativeAlertsTenantList ? (
    <table className="table-income">
      <thead className="table-income-thead">
        <tr>
          <th>Name</th>
          <th>Category</th>
        </tr>
      </thead>
      <tbody>
        {generateMonthlyPdfReport.negativeNewsList !== "" &&
          generateMonthlyPdfReport.negativeNewsList.map((negativeNews, i) => (
            <tr key={i}>
              <td>{negativeNews.tenantName}</td>
              <td>{negativeNews.category.join(", ")}</td>
            </tr>
          ))}
      </tbody>
    </table>
  ) : (
    noDataAvailableNegativeAlertsTenantList && (
      <p
        style={{
          fontSize: "11px",
          marginTop: "20px",
          fontWeight: "900",
          textAlign: "left",
        }}
      >
        No Data Available for negative news alerts...
      </p>
    )
  )}
</div>




 </Card>
</div>  
  );

 }else{
 return(
 
 <div align="center">
 <Loader
     type="ThreeDots"
     color="#00BFFF"
     height={500}
     width={100}
     timeout={8000} //3 secs
   /></div>
   )
 }
 }
  
PdfMonthlyReport.propTypes = {

  loading: PropTypes.bool.isRequired,
  tmGenerateMonthlyPdfReportRequest: PropTypes.func.isRequired,
  tmGenerateMonthlyOrgDetailsPdfDownloadRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  generateMonthlyPdfReport: state.tenantmonitoring.generateMonthlyPdfReport,
  });              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PdfMonthlyReport);
