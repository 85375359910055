import { all, takeLatest } from 'redux-saga/effects';

import { Types as OrderTypes } from '../ducks/orders';
import { Types as UserTypes } from '../ducks/user';
import { Types as AuthTypes } from '../ducks/auth';
import { Types as TmTypes } from '../ducks/tenantmonitoring';

import { getOrders } from './orders';
import { login } from './user';
import { signIn, logout,loggeduser } from './auth';
import { getTenantMonitoringList,
         getTenantMonitoringListByFund,
         getTenantMonitoringListByProperty,
         getTenantMonitoringNotificationList,
         getBankoNotificationList,
         getPirsScore,
         getDashboard,
         getPropertyOverAllScoreByFund,
         getCreditScore,
         getScoreCommentary,
         getDerogatoryScoreCount,
         getFundRiskCount,
         getTenantsScoreByRange,
         getTenantsOverAlCredPirsDeroScore,
         getTenantsScoreByType,
         getTenantsCountByFund,
         getScoreStatus,
         getTenantOverAllScoreByProperty,
         getFundsByOrg,
         getTenantByRange,
         getTenantsRiskCountByFund,
         getPropertyListByOrg,
         getTenantsCountByProperty,
         getTenantsRiskCountByProperty,
         getTenantsPropertyByOrganization,
         getTenantsScoreByPIRSType,
         getTenantByPropertyRange,
         getTenantNameByPropertyRange,
         getPropertyByFund,
         getPropertyListOfOrgByAssetManager,
         getPropertyDashboardCount,
         getPropertyCountByOrg,
         getPropertyCountByOrgAndUser,


        saveFund,
         editFund,
         deleteFund,
         listFund,
         getFundById,
         getFundDashboardCount,
         getFundCountByOrg,

         saveCompany,
         editCompany,
         deleteCompany,
         listCompany,
         getCompanyById,

         saveProperty,
         editProperty,
         deleteProperty,
         listProperty,
         getPropertyById,
         
         saveTenant,
         editTenant,
         deleteTenant,
         listTenant,
         uploadTenant,
         getTenantById,
         getTenantsCountByPropertyRange,

         saveUser,
         listUser,
         editUser,
         deleteUser,

         enableUser,
         disableUser,

         getUserNameAndRolebyLogin,
         getActiveTaxLiensJudgmentDetails,
         getUserById,

         getContifyCompanyInsightSearchData,

         getContifyCompanySearch,
         getCreditSafeCompanyList,
         getCorteraBusinessSearch,
         getTloBusinessSearch,
         getFundListByOrg,
         getTenantListByOrg,
         //getPropertyListByOrg,
         getUserListByOrg,
         getCompanyListByOrg,

         getFundListWithUser,
         getPropertyListWithUser,

         getFundsByOrgCompanyAdmin,

         getFundManagerList,
         getAssetManagerList,


         getMonitoredTenantsByFund, 
         getMonitoredTenantsByProperty,
         getMonitoredTenantsByCompanyAdmin,


         getSaveNotificationSettings,
         getEditNotificationSettings,
         getNotificationSettingsById,

         getFundPropertyOverAllScore,
         getCompanyAdminDashboard,
         createTenantInitialScore,
         getTaxLienDetailsByTenantId,
         createTrailingScoresForAllTenants,
         getTaxLienJudgmentScoreByTenantId,
         getJudgmentDetailsByTenantId,
         getSuitDetailsByTenantId,
         getUccDetailsByTenantId,

         getPopulateInitialDataForTenant,
         getTenantUploadStatusByOrgId,
         getTenantFailureStatusLogListByOrg,
         getOrganizationScoreExecutionLogByOrg,
         getOrganizationScoreExecutionLogList,
         getScoreExecutionLogListByTenantId,
         getDistinctTenantsFromScoreExecutionLog,
         getOrgInitialDataStatus,
         getPopulateInitialDataByOrg,
         getBankoReportByTenant,

         getUserByLoginAndEmail,
         getChangePassword,

         getMonotoredTenantsNews,
         getTenantMonitoringPublicRecordNotificationList,
         getUploadPirsLogList,
         uploadPirsCsv,
         getPirsList,
         generateDashboard,
         getDashboardUserList,
         generateDashboardByUser,
         getTenantListByRiskBasedOnCategory,
         getTenantScoreListByRiskBasedOnCategory,

         getFundDashboardGridView,
         getCompanyAdminFundDashboardGridView,
         getFundPropertyGridViewDashboard,
         getPropertyDashboardGridView,
         getPropertyImageUpload,
         getFundImageUpload,

         getFundPropertyApexChartData,
         getFundApexChartData,
         getPropertyApexChartData,
         getTenantScoreGraphDataByPropertyIdAndUserId,
         getTenantRiskCountByPropertyGridView,
         getTenantBarGraphData,
         getPropertyTenantLineChart,
         getPropertyTenantLineChartPagination,
         getPropertyTenantSearch,
         getPreviousMonthTenantRiskCount,
         getTenantListByRiskBasedOnLowRisk,
         getTenantListByRiskBasedOnMediumLowRisk,
         getTenantListByRiskBasedOnMediumHighRisk,
         getTenantListByRiskBasedOnModerateHighRisk,
         getTenantListByRiskBasedOnHighRisk,
        // getPropertySearchList,
        // savePropertySearchList,

         getBankruptcyDetailsOfUser,
         saveCompanySearchList,
         savePropertySearch,
         savePropertySearchList,
         saveCompanySearch,
         getSendEmail,
         getTenantCountInRiskCount,
         genaerateFundInitialReport,
         genaeratePropertyInitialReport,
         genaerateTenantInitialReport,
         generateScoreForFund,
         getCommentaryFromCompanyReport,
         getCreditSafeBankruptcyReport,
         getCurrentMonthFundScoreByFundId,
         getPropertyCityAndStateByPropertyId,
         getNotificationLogList,
         getTenantPropertyDetails,
         getContifyTopics,
         getContifyListUsingTopics,
        
         saveParent,
         getParentListByOrg,
         getParentById,
         editParent,
         deleteParent,
         getParentListByOrgInTenant,
         unlinkParent,
         genaerateParentInitialReport,
         getParentCreditScore,
         getParentDerogatoryScoreCount,
         getParentScoreByPIRSType,
         getMonotoredParentsNews,
         getCreditSafeParentBankruptcyReport,
         getParentContifyListUsingTopics,
         getUccDetailsByParentId,
         getTaxLienDetailsByParentId,
         getJudgmentDetailsByParentId,
         getSuitDetailsByParentId,
         getNotificationLogSearchList,
         getNewsFtl,
         getDerogatoryFtl,
         getCreditFtl,
         getTenantScoreFtl,
         getSuitsFtl,
         getPirsFtl,
         getActiveStatusFtl,
         getPropertyOverallScoreFtl,
         getFundOverallScoreFtl,
         getCautionaryUccMonthlyFtl,
         getCautionaryUccInitialPopulateMonthlyFtl,
         getLiensMonthlyFtl,
         getLiensInitialPopulateMonthlyFtl,
         getJudgementMonthlyFtl,
         getJudgementInitialPopulateMonthlyFtl,
         saveCommentary,
         getCommentaryList,
         getTenantReportsDetails,
         generateTenantReport,
         generateMonthlyPdfReport,
         generateMonthlyPropertyDetailsPdfReport,
         generateRiskListGraphMonthlyReport,
         saveGraphMonthlyReport,
         generateMonthlyFundReport,
         cleanUpScore,
         cleanUpNews,
         saveTenantNewsSettings,
         editTenantNewsSettings,
         getTenantNewsSettingsById,
         getPropertyDashboardGridViewByFundId,
         generateMonthlyPropertyDetailsPdfDownload,
         generateMonthlyFundDetailsPdfDownload,
         generateMonthlyOrgDetailsPdfDownload
         
        } from './tenantmonitoring';





export default function* rootSaga() {
  yield all([takeLatest(AuthTypes.SIGN_REQUEST, signIn)]);
  yield all([takeLatest(AuthTypes.LOGOUT, logout)]);
  yield all([takeLatest(AuthTypes.LOGGEDIN_USER_REQUEST , loggeduser)]);
  yield all([takeLatest(UserTypes.LOGIN_REQUEST, login)]);
  yield all([takeLatest(OrderTypes.GET_REQUEST, getOrders)]);
  yield all([takeLatest(TmTypes.DASHBOARD_REQUEST, getDashboard)]);
  yield all([takeLatest(TmTypes.PROPERTY_DASHBOARD_BY_FUND_REQUEST, getPropertyOverAllScoreByFund)]);
  yield all([takeLatest(TmTypes.PIRS_REQUEST, getPirsScore)]);
  // yield all([takeLatest(TmTypes.DEROGATORY_SCORE_COUNT_REQUEST, getCreditScore)]);
  yield all([takeLatest(TmTypes.CREDIT_SCORE_REQUEST, getCreditScore)]);
  yield all([takeLatest(TmTypes.DEROGATORY_SCORE_COUNT_REQUEST, getDerogatoryScoreCount)]);
  yield all([takeLatest(TmTypes.FUND_RISK_COUNT_REQUEST, getFundRiskCount)]);
  yield all([takeLatest(TmTypes.TENANTS_SCORE_BY_RANGE_REQUEST, getTenantsScoreByRange)]);
  yield all([takeLatest(TmTypes.TENANTS_BY_FUNDID_RANGE_REQUEST, getTenantByRange)]);
  yield all([takeLatest(TmTypes.TENANTS_OVERAL_PIRS_CRED_DERO_SCORE_REQUEST, getTenantsOverAlCredPirsDeroScore)]);
  yield all([takeLatest(TmTypes.TENANTS_SCORE_BY_TYPE_REQUEST, getTenantsScoreByType)]);
  yield all([takeLatest(TmTypes.TENANTS_COUNT_BY_FUND_REQUEST, getTenantsCountByFund)]);
  yield all([takeLatest(TmTypes.SCORE_STATUS_REQUEST, getScoreStatus)]);
  yield all([takeLatest(TmTypes.TENANT_OVERALL_SCORE_BY_PROPERTY_REQUEST, getTenantOverAllScoreByProperty)]);
  yield all([takeLatest(TmTypes.FUNDS_BY_ORG_REQUEST, getFundsByOrg)]);
  yield all([takeLatest(TmTypes.SCORE_COMMENTARY_REQUEST, getScoreCommentary)]);
  yield all([takeLatest(TmTypes.TENANTS_RISK_COUNT_BY_FUND_REQUEST, getTenantsRiskCountByFund)]);
  yield all([takeLatest(TmTypes.PROPERTY_BY_ORG_REQUEST, getPropertyListByOrg)]);

  yield all([takeLatest(TmTypes.PROPERTY_WITH_USER_REQUEST, getPropertyListWithUser)]);

  

  yield all([takeLatest(TmTypes.TENANTS_COUNT_BY_PROPERTY_REQUEST, getTenantsCountByProperty)]);
  yield all([takeLatest(TmTypes.TENANTS_RISK_COUNT_BY_PROPERTY_REQUEST, getTenantsRiskCountByProperty)]);
  yield all([takeLatest(TmTypes.PROPERTY_BY_TENANT_ORGID_REQUEST, getTenantsPropertyByOrganization)]);

  yield all([takeLatest(TmTypes.PROPERTY_BY_FUND_REQUEST, getPropertyByFund)]);
  yield all([takeLatest(TmTypes.LIST_REQUEST, getTenantMonitoringList)]);

  yield all([takeLatest(TmTypes.TENANT_MONITORING_LIST_BY_FUND_REQUEST, getTenantMonitoringListByFund)]);
  yield all([takeLatest(TmTypes.TENANT_MONITORING_LIST_BY_PROPERTY_REQUEST, getTenantMonitoringListByProperty)]);

  yield all([takeLatest(TmTypes.LIST_BANKO_NOTIFICATION_REQUEST, getBankoNotificationList)]);
  yield all([takeLatest(TmTypes.LIST_NOTIFICATION_REQUEST, getTenantMonitoringNotificationList)]);
  yield all([takeLatest(TmTypes.LIST_LEXIS_NEXIS_NOTIFICATION_REQUEST, getTenantMonitoringPublicRecordNotificationList)]);
  yield all([takeLatest(TmTypes.TENANTS_MONTH_PIRS_REQUEST, getTenantsScoreByPIRSType)]);
  yield all([takeLatest(TmTypes.TENANTS_BY_PROPERTY_REQUEST, getTenantByPropertyRange)]);
  yield all([takeLatest(TmTypes.TENANTS_BY_PROPERTY_RANGE_REQUEST, getTenantNameByPropertyRange)]);
  
//jain

  yield all([takeLatest(TmTypes.FUND_SAVE_REQUEST, saveFund)]);
  yield all([takeLatest(TmTypes.FUND_EDIT_REQUEST, editFund)]);
  yield all([takeLatest(TmTypes.FUND_DELETE_REQUEST, deleteFund)]);
  yield all([takeLatest(TmTypes.FUND_LIST_REQUEST, listFund)]);


  yield all([takeLatest(TmTypes.COMPANY_SAVE_REQUEST, saveCompany)]);
  yield all([takeLatest(TmTypes.COMPANY_EDIT_REQUEST, editCompany)]);
  yield all([takeLatest(TmTypes.COMPANY_DELETE_REQUEST, deleteCompany)]);
  yield all([takeLatest(TmTypes.COMPANY_LIST_REQUEST, listCompany)]);

  yield all([takeLatest(TmTypes.PROPERTY_SAVE_REQUEST, saveProperty)]);
  yield all([takeLatest(TmTypes.PROPERTY_EDIT_REQUEST, editProperty)]);
  yield all([takeLatest(TmTypes.PROPERTY_DELETE_REQUEST, deleteProperty)]);
  yield all([takeLatest(TmTypes.PROPERTY_LIST_REQUEST, listProperty)]);
 
 
  yield all([takeLatest(TmTypes.TENANT_SAVE_REQUEST, saveTenant)]);
  yield all([takeLatest(TmTypes.TENANT_EDIT_REQUEST, editTenant)]);
  yield all([takeLatest(TmTypes.TENANT_DELETE_REQUEST, deleteTenant)]);
  yield all([takeLatest(TmTypes.TENANT_LIST_REQUEST, listTenant)]);
 yield all([takeLatest(TmTypes.UPLOAD_TENANT_REQUEST, uploadTenant)]);

 yield all([takeLatest(TmTypes.USER_LIST_REQUEST, listUser)]);
 yield all([takeLatest(TmTypes.USER_SAVE_REQUEST, saveUser)]);
 yield all([takeLatest(TmTypes.USER_EDIT_REQUEST, editUser)]);
 yield all([takeLatest(TmTypes.USER_DELETE_REQUEST, deleteUser)]);

yield all([takeLatest(TmTypes.USER_ENABLE_REQUEST, enableUser)]);
yield all([takeLatest(TmTypes.USER_DISABLE_REQUEST, disableUser)]);


 
 yield all([takeLatest(TmTypes.USER_NAME_ROLE_REQUEST, getUserNameAndRolebyLogin)]);

 yield all([takeLatest(TmTypes.CONTIFY_COMPANY_INSIGHT_SEARCH_BY_TRIGGERID_REQUEST, getContifyCompanyInsightSearchData)]);

 yield all([takeLatest(TmTypes.ACTIVE_TAXLIEN_JUDGMENT_DETAILS_REQUEST, getActiveTaxLiensJudgmentDetails)]);
//Angel



 yield all([takeLatest(TmTypes.CONTIFY_COMPANY_SEARCH_REQUEST, getContifyCompanySearch)]);
 yield all([takeLatest(TmTypes.CREDITSAFE_COMPANY_LIST_REQUEST, getCreditSafeCompanyList)]);
 yield all([takeLatest(TmTypes.CORTERA_BUSINESS_SEARCH_REQUEST, getCorteraBusinessSearch)]);
 yield all([takeLatest(TmTypes.TLO_COMPANY_SEARCH_REQUEST, getTloBusinessSearch)]);


 yield all([takeLatest(TmTypes.FUND_LIST_BY_ORG_REQUEST, getFundListByOrg)]);

 yield all([takeLatest(TmTypes.FUNDS_WITH_USER_REQUEST, getFundListWithUser)]);

 

 yield all([takeLatest(TmTypes.TENANT_LIST_BY_ORG_REQUEST, getTenantListByOrg)]);
 yield all([takeLatest(TmTypes.PROPERTY_LIST_BY_ORG_REQUEST, getPropertyListByOrg)]);
 yield all([takeLatest(TmTypes.USER_LIST_BY_ORG_REQUEST, getUserListByOrg)]);
 yield all([takeLatest(TmTypes.COMPANY_LIST_BY_ORG_REQUEST, getCompanyListByOrg)]);

 yield all([takeLatest(TmTypes.FUNDS_BY_ORG_COMPANY_ADMIN_REQUEST, getFundsByOrgCompanyAdmin)]);
  

 yield all([takeLatest(TmTypes.FUND_MANAGER_LIST_REQUEST, getFundManagerList)]);
 yield all([takeLatest(TmTypes.ASSET_MANAGER_LIST_REQUEST, getAssetManagerList)]);

 yield all([takeLatest(TmTypes.FUND_PROPERTY_DASHBOARD_REQUEST, getFundPropertyOverAllScore)]);
 yield all([takeLatest(TmTypes.COMPANY_ADMIN_DASHBOARD_REQUEST, getCompanyAdminDashboard)]);  
 yield all([takeLatest(TmTypes.TENANT_INITIAL_SCORE_REQUEST, createTenantInitialScore)]);    
 
 yield all([takeLatest(TmTypes.FUND_BY_ID_REQUEST, getFundById)]); 
 yield all([takeLatest(TmTypes.COMPANY_BY_ID_REQUEST, getCompanyById)]); 
 yield all([takeLatest(TmTypes.TENANT_BY_ID_REQUEST, getTenantById)]);
 yield all([takeLatest(TmTypes.USER_BY_ID_REQUEST, getUserById)]); 
 yield all([takeLatest(TmTypes.PROPERTY_BY_ID_REQUEST, getPropertyById)]);  

 yield all([takeLatest(TmTypes.PROPERTY_LIST_OF_ORG_BY_ASSET_MANAGER_REQUEST, getPropertyListOfOrgByAssetManager)]);  

 yield all([takeLatest(TmTypes.ACTIVE_LIENS_DETAILS_BY_TENANT_ID_REQUEST, getTaxLienDetailsByTenantId)]);  
 yield all([takeLatest(TmTypes.FUND_DASHBOARD_COUNT_REQUEST, getFundDashboardCount)]); 
 yield all([takeLatest(TmTypes.PROPERTY_DASHBOARD_COUNT_REQUEST, getPropertyDashboardCount)]);  
 yield all([takeLatest(TmTypes.TENANTS_COUNT_BY_PROPERTY_RANGE_REQUEST, getTenantsCountByPropertyRange)]);  
 yield all([takeLatest(TmTypes.PROPERTY_COUNT_BY_ORG_REQUEST, getPropertyCountByOrg)]);  
 yield all([takeLatest(TmTypes.FUND_COUNT_BY_ORG_REQUEST, getFundCountByOrg)]);  
 yield all([takeLatest(TmTypes.ALL_TENANTS_TRAILING_SCORE_REQUEST, createTrailingScoresForAllTenants)]);  
 yield all([takeLatest(TmTypes.ACTIVE_LIENS_JUDGMENT_SCORE_BY_TENANT_ID_REQUEST, getTaxLienJudgmentScoreByTenantId)]); 

 yield all([takeLatest(TmTypes.ACTIVE_JUDGMENT_DETAILS_BY_TENANT_ID_REQUEST, getJudgmentDetailsByTenantId)]);
 yield all([takeLatest(TmTypes.ACTIVE_SUIT_DETAILS_BY_TENANT_ID_REQUEST, getSuitDetailsByTenantId)]);
 yield all([takeLatest(TmTypes.ACTIVE_UCC_DETAILS_BY_TENANT_ID_REQUEST, getUccDetailsByTenantId)]);
 



 yield all([takeLatest(TmTypes.MONITORED_TENANTS_BY_FUND_REQUEST, getMonitoredTenantsByFund)]);
 yield all([takeLatest(TmTypes.MONITORED_TENANTS_BY_PROPERTY_REQUEST, getMonitoredTenantsByProperty)]);
 yield all([takeLatest(TmTypes.MONITORED_TENANTS_BY_COMPANY_ADMIN_REQUEST, getMonitoredTenantsByCompanyAdmin)]);

 yield all([takeLatest(TmTypes.SAVE_NOTIFICATION_SETTINGS_REQUEST, getSaveNotificationSettings)]);
 yield all([takeLatest(TmTypes.EDIT_NOTIFICATION_SETTINGS_REQUEST, getEditNotificationSettings)]);
 yield all([takeLatest(TmTypes.NOTIFICATION_SETTINGS_BY_ID_REQUEST, getNotificationSettingsById)]);
 yield all([takeLatest(TmTypes.MONITORED_TENANTS_NEWS_REQUEST, getMonotoredTenantsNews)]);

 yield all([takeLatest(TmTypes.POPULATE_INITIAL_DATA_FOR_TENANT_REQUEST, getPopulateInitialDataForTenant)]);

 yield all([takeLatest(TmTypes.TENANT_UPLOAD_STATUS_BY_ORG_ID_REQUEST, getTenantUploadStatusByOrgId)]);

 yield all([takeLatest(TmTypes.TENANT_FAILURE_STATUS_LOG_LIST_BY_ORG_REQUEST, getTenantFailureStatusLogListByOrg)]);
 yield all([takeLatest(TmTypes.ORGANIZATION_SCORE_EXECUTION_LOG_BY_ORG_REQUEST, getOrganizationScoreExecutionLogByOrg)]);

 yield all([takeLatest(TmTypes.ORGANIZATION_SCORE_EXECUTION_LOG_LIST_REQUEST, getOrganizationScoreExecutionLogList)]);

 yield all([takeLatest(TmTypes.SCORE_EXECUTION_LOG_LIST_BY_TENANT_ID_REQUEST, getScoreExecutionLogListByTenantId)]);

 yield all([takeLatest(TmTypes.DISTINCT_TENANTS_FROM_SCORE_EXECUTION_LOG_REQUEST, getDistinctTenantsFromScoreExecutionLog)]);
 yield all([takeLatest(TmTypes.ORG_INITIAL_DATA_STATUS_REQUEST, getOrgInitialDataStatus)]);
 yield all([takeLatest(TmTypes.POPULATE_INITIAL_DATA_BY_ORG_REQUEST, getPopulateInitialDataByOrg)]);
 

 yield all([takeLatest(TmTypes.GET_BANKO_REPORT_BY_TENANT_REQUEST, getBankoReportByTenant)]);
 yield all([takeLatest(TmTypes.GET_USER_BY_LOGIN_AND_EMAIL_REQUEST, getUserByLoginAndEmail)]);
 
 yield all([takeLatest(TmTypes.CHANGE_PASSWORD_WITH__USER_ID_AND_PASSWORD_REQUEST, getChangePassword)]);
 yield all([takeLatest(TmTypes.PROPERTY_COUNT_BY_ORG_AND_USER_REQUEST, getPropertyCountByOrgAndUser)]);
 
 yield all([takeLatest(TmTypes.UPLOAD_PIRS_LOG_LIST_REQUEST, getUploadPirsLogList)]);
 yield all([takeLatest(TmTypes.UPLOAD_PIRS_CSV_REQUEST, uploadPirsCsv)]);
 yield all([takeLatest(TmTypes.PIRS_LIST_REQUEST, getPirsList)]);
 yield all([takeLatest(TmTypes.GENERATE_DASHBOARD_REQUEST, generateDashboard)]);
 yield all([takeLatest(TmTypes.DASHBOARD_USER_LIST_REQUEST, getDashboardUserList)]);
 yield all([takeLatest(TmTypes.GENERATE_DASHBOARD_BY_USER_REQUEST, generateDashboardByUser)]);
 yield all([takeLatest(TmTypes.TENANT_LIST_BY_RISK_REQUEST, getTenantListByRiskBasedOnCategory)]);

 yield all([takeLatest(TmTypes.TENANT_SCORE_LIST_BY_RISK_REQUEST, getTenantScoreListByRiskBasedOnCategory)]);


 yield all([takeLatest(TmTypes.FUND_DASHBOARD_GRID_VIEW_REQUEST, getFundDashboardGridView)]);
 yield all([takeLatest(TmTypes.COMPANY_ADMIN_FUND_DASHBOARD_GRID_VIEW_REQUEST, getCompanyAdminFundDashboardGridView)]);
 yield all([takeLatest(TmTypes.GET_FUND_PROPERTY_GRID_VIEW_DASHBOARD_REQUEST, getFundPropertyGridViewDashboard)]);
 yield all([takeLatest(TmTypes.PROPERTY_DASHBOARD_GRID_VIEW_REQUEST, getPropertyDashboardGridView)]);
 
 yield all([takeLatest(TmTypes.GET_BANKRUPTCY_DETAILS_OF_USER_REQUEST, getBankruptcyDetailsOfUser)]);

 yield all([takeLatest(TmTypes.COMPANY_SEARCH_LIST_SAVE_REQUEST, saveCompanySearchList)]);

 yield all([takeLatest(TmTypes.PROPERTY_SEARCH_REQUEST, savePropertySearch)]);

 yield all([takeLatest(TmTypes.PROPERTY_SEARCH_LIST_SAVE_REQUEST, savePropertySearchList)]);

 yield all([takeLatest(TmTypes.COMPANY_SEARCH_REQUEST, saveCompanySearch)]);

 yield all([takeLatest(TmTypes.SEND_EMAIL_REQUEST, getSendEmail)]);

 yield all([takeLatest(TmTypes.PROPERTY_IMAGE_UPLOAD_REQUEST, getPropertyImageUpload)]);
 yield all([takeLatest(TmTypes.FUND_IMAGE_UPLOAD_REQUEST, getFundImageUpload)]);


 yield all([takeLatest(TmTypes.FUND_PROPERTY_APEX_CHART_DATA_REQUEST, getFundPropertyApexChartData)]);
 yield all([takeLatest(TmTypes.FUND_APEX_CHART_DATA_REQUEST, getFundApexChartData)]);
 yield all([takeLatest(TmTypes.PROPERTY_APEX_CHART_DATA_REQUEST, getPropertyApexChartData)]);
 
 yield all([takeLatest(TmTypes.GET_TENANT_SCORE_GRAPH_DATA_BY_PROPERTYID_AND_USERID_REQUEST, getTenantScoreGraphDataByPropertyIdAndUserId)]);
 
 yield all([takeLatest(TmTypes.TOTAL_TENANT_COUNT_IN_RISK_COUNT_REQUEST, getTenantCountInRiskCount)]);

 yield all([takeLatest(TmTypes.GENERATE_FUND_INITIAL_REPORT_REQUEST, genaerateFundInitialReport)]);

 yield all([takeLatest(TmTypes.GENERATE_PROPERTY_INITIAL_REPORT_REQUEST, genaeratePropertyInitialReport)]);

 yield all([takeLatest(TmTypes.GENERATE_TENANT_INITIAL_REPORT_REQUEST, genaerateTenantInitialReport)]);

 yield all([takeLatest(TmTypes.GENERATE_SCORE_FOR_FUND_REQUEST, generateScoreForFund)]);

 
 yield all([takeLatest(TmTypes.GET_TENANT_RISK_COUNT_BY_PROPERTY_GRID_VIEW_REQUEST, getTenantRiskCountByPropertyGridView)]);

 yield all([takeLatest(TmTypes.GET_TENANT_BAR_GRAPH_DATA_REQUEST, getTenantBarGraphData)]);

 yield all([takeLatest(TmTypes.GET_PROPERTY_TENANT_LINE_CHART_REQUEST, getPropertyTenantLineChart)]);

 yield all([takeLatest(TmTypes.GET_PROPERTY_TENANT_LINE_CHART_PAGINATION_REQUEST, getPropertyTenantLineChartPagination)]);

 yield all([takeLatest(TmTypes.GET_PROPERTY_TENANT_SEARCH_REQUEST, getPropertyTenantSearch)]);

 yield all([takeLatest(TmTypes.GET_PREVIOUS_MONTH_TENANT_RISK_COUNT_REQUEST, getPreviousMonthTenantRiskCount)]);

 yield all([takeLatest(TmTypes.GET_TENANT_LIST_BASED_ON_LOW_RISK_REQUEST, getTenantListByRiskBasedOnLowRisk)]);

 yield all([takeLatest(TmTypes.GET_TENANT_LIST_BASED_ON_MEDIUM_LOW_RISK_REQUEST, getTenantListByRiskBasedOnMediumLowRisk)]);
 
 yield all([takeLatest(TmTypes.GET_TENANT_LIST_BASED_ON_MEDIUM_HIGH_RISK_REQUEST, getTenantListByRiskBasedOnMediumHighRisk)]);
 
 yield all([takeLatest(TmTypes.GET_TENANT_LIST_BASED_ON_MODERATE_HIGH_RISK_REQUEST, getTenantListByRiskBasedOnModerateHighRisk)]);

 yield all([takeLatest(TmTypes.GET_TENANT_LIST_BASED_ON_HIGH_RISK_REQUEST, getTenantListByRiskBasedOnHighRisk)]);

 yield all([takeLatest(TmTypes.GET_COMMENTARY_FROM_COMPANY_REPORT_REQUEST, getCommentaryFromCompanyReport)]);

 yield all([takeLatest(TmTypes.CREDITSAFE_BANKRUPTCY_REPORT_BY_TENANTID_REQUEST, getCreditSafeBankruptcyReport)]);
 yield all([takeLatest(TmTypes.CURRENT_MONTH_FUND_SCORE_BY_FUNDID_REQUEST, getCurrentMonthFundScoreByFundId)]);
 yield all([takeLatest(TmTypes.PROPERTY_CITY_AND_STATE_BY_PROPERTYID_REQUEST, getPropertyCityAndStateByPropertyId)]);
 yield all([takeLatest(TmTypes.NOTIFICATION_LOG_LIST_REQUEST, getNotificationLogList)]);
 yield all([takeLatest(TmTypes.TENANT_PROPERTY_DETAILS_REQUEST, getTenantPropertyDetails)]);
 yield all([takeLatest(TmTypes.CONTIFY_TOPICS_REQUEST, getContifyTopics)]);
 yield all([takeLatest(TmTypes.GET_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST, getContifyListUsingTopics)]);

 yield all([takeLatest(TmTypes.PARENT_SAVE_REQUEST, saveParent)]);
 yield all([takeLatest(TmTypes.PARENT_LIST_BY_ORG_REQUEST, getParentListByOrg)]);
 yield all([takeLatest(TmTypes.PARENT_BY_ID_REQUEST, getParentById)]);
 yield all([takeLatest(TmTypes.PARENT_EDIT_REQUEST, editParent)]);
 yield all([takeLatest(TmTypes.PARENT_DELETE_REQUEST, deleteParent)]);
 yield all([takeLatest(TmTypes.PARENT_BY_ORG_REQUEST, getParentListByOrgInTenant)]);
 yield all([takeLatest(TmTypes.UNLINK_PARENT_REQUEST, unlinkParent)]);
 yield all([takeLatest(TmTypes.GENERATE_PARENT_INITIAL_REPORT_REQUEST, genaerateParentInitialReport)]);
 yield all([takeLatest(TmTypes.PARENT_CREDIT_SCORE_REQUEST, getParentCreditScore)]);
 yield all([takeLatest(TmTypes.PARENT_DEROGATORY_SCORE_COUNT_REQUEST, getParentDerogatoryScoreCount)]);
 yield all([takeLatest(TmTypes.PARENT_MONTH_PIRS_REQUEST, getParentScoreByPIRSType)]);
 yield all([takeLatest(TmTypes.MONITORED_PARENTS_NEWS_REQUEST, getMonotoredParentsNews)]);
 yield all([takeLatest(TmTypes.CREDITSAFE_BANKRUPTCY_REPORT_BY_PARENTID_REQUEST, getCreditSafeParentBankruptcyReport)]);
 yield all([takeLatest(TmTypes.GET_PARENT_COMPANY_INSIGHT_SEARCH_LIST_USING_TOPIC_REQUEST, getParentContifyListUsingTopics)]);
 yield all([takeLatest(TmTypes.ACTIVE_UCC_DETAILS_BY_PARENT_ID_REQUEST, getUccDetailsByParentId)]);
 yield all([takeLatest(TmTypes.ACTIVE_LIENS_DETAILS_BY_PARENT_ID_REQUEST, getTaxLienDetailsByParentId)]);  
 yield all([takeLatest(TmTypes.ACTIVE_JUDGMENT_DETAILS_BY_PARENT_ID_REQUEST, getJudgmentDetailsByParentId)]);
 yield all([takeLatest(TmTypes.ACTIVE_SUIT_DETAILS_BY_PARENT_ID_REQUEST, getSuitDetailsByParentId)]);

 yield all([takeLatest(TmTypes.NOTIFICATION_LOG_SEARCH_LIST_REQUEST, getNotificationLogSearchList)]);
 yield all([takeLatest(TmTypes.NEWS_FTL_REQUEST, getNewsFtl)]);
 yield all([takeLatest(TmTypes.DEROGATORY_FTL_REQUEST, getDerogatoryFtl)]);
 yield all([takeLatest(TmTypes.CREDIT_FTL_REQUEST, getCreditFtl)]);
 yield all([takeLatest(TmTypes.TENANT_SCORE_FTL_REQUEST, getTenantScoreFtl)]);
 yield all([takeLatest(TmTypes.SUITS_FTL_REQUEST, getSuitsFtl)]);
 yield all([takeLatest(TmTypes.PIRS_FTL_REQUEST, getPirsFtl)]);

 yield all([takeLatest(TmTypes.ACTIVE_STATUS_FTL_REQUEST, getActiveStatusFtl)]);
 yield all([takeLatest(TmTypes.PROPERTY_OVERALL_SCORE_FTL_REQUEST, getPropertyOverallScoreFtl)]);
 yield all([takeLatest(TmTypes.FUND_OVERALL_SCORE_FTL_REQUEST, getFundOverallScoreFtl)]);
 yield all([takeLatest(TmTypes.CAUTIONARY_UCC_MONTHLY_FTL_REQUEST, getCautionaryUccMonthlyFtl)]);
 yield all([takeLatest(TmTypes.CAUTIONARY_UCC_INITIAL_POPULATE_FTL_REQUEST, getCautionaryUccInitialPopulateMonthlyFtl)]);
 yield all([takeLatest(TmTypes.LIENS_MONTHLY_FTL_REQUEST, getLiensMonthlyFtl)]);
 yield all([takeLatest(TmTypes.LIENS_INITIAL_POPULATE_FTL_REQUEST, getLiensInitialPopulateMonthlyFtl)]);
 yield all([takeLatest(TmTypes.JUDGEMENT_MONTHLY_FTL_REQUEST, getJudgementMonthlyFtl)]);
 yield all([takeLatest(TmTypes.JUDGEMENT_INITIAL_POPULATE_FTL_REQUEST, getJudgementInitialPopulateMonthlyFtl)]);
 yield all([takeLatest(TmTypes.TENANT_COMMENTARY_SAVE_REQUEST, saveCommentary)]);
 yield all([takeLatest(TmTypes.COMMENTARY_LIST_REQUEST, getCommentaryList)]);
 yield all([takeLatest(TmTypes.TENANT_REPORTS_DETAILS_REQUEST, getTenantReportsDetails)]);
 yield all([takeLatest(TmTypes.GENERATE_TENANT_REPORT_REQUEST, generateTenantReport)]);
 yield all([takeLatest(TmTypes.GENERATE_MONTHLY_PDF_REPORT_REQUEST, generateMonthlyPdfReport)]);
//  yield all([takeLatest(TmTypes.GENERATE_MONTHLY_FUND_DETAILS_PDF_REPORT_REQUEST, generateMonthlyFundDetailsPdfReport)]);
 yield all([takeLatest(TmTypes.GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_REPORT_REQUEST, generateMonthlyPropertyDetailsPdfReport)]);
 yield all([takeLatest(TmTypes.RISK_LIST_GRAPH_MONTHLY_REPORT_REQUEST, generateRiskListGraphMonthlyReport)]);
 yield all([takeLatest(TmTypes.GRAPH_MONTHLY_SAVE_REQUEST, saveGraphMonthlyReport)]);
 yield all([takeLatest(TmTypes.GENERATE_MONTHLY_FUND_REPORT_REQUEST, generateMonthlyFundReport)]);
 yield all([takeLatest(TmTypes.CLEAN_UP_SCORE_REQUEST, cleanUpScore)]);
 yield all([takeLatest(TmTypes.CLEAN_UP_NEWS_REQUEST, cleanUpNews)]);
 yield all([takeLatest(TmTypes.TENANT_NEWS_SETTINGS_REQUEST, saveTenantNewsSettings)]);
 yield all([takeLatest(TmTypes.EDIT_TENANT_NEWS_SETTINGS_REQUEST, editTenantNewsSettings)]);
 yield all([takeLatest(TmTypes.TENANT_NEWS_SETTINGS_BY_ID_REQUEST, getTenantNewsSettingsById)]);
 
 yield all([takeLatest(TmTypes.PROPERTY_DASHBOARD_GRID_VIEW_BY_FUNDID_REQUEST, getPropertyDashboardGridViewByFundId)]);
 yield all([takeLatest(TmTypes.GENERATE_MONTHLY_PROPERTY_DETAILS_PDF_DOWNLOAD_REQUEST, generateMonthlyPropertyDetailsPdfDownload)]);
 yield all([takeLatest(TmTypes.GENERATE_MONTHLY_FUND_DETAILS_PDF_DOWNLOAD_REQUEST, generateMonthlyFundDetailsPdfDownload)]);
 yield all([takeLatest(TmTypes.GENERATE_MONTHLY_ORG_DETAILS_PDF_DOWNLOAD_REQUEST, generateMonthlyOrgDetailsPdfDownload)]);

 
 // yield all([takeLatest(TmTypes.PROPERTY_SEARCH_REQUEST, getPropertySearchList)]);

// /yield all([takeLatest(TmTypes.PROPERTY_SEARCH_LIST_SAVE_REQUEST, savePropertySearchList)]);

}

