import React from 'react';

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";


import Moment from 'moment';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Card } from '@material-ui/core';
// import roboto from "assets/roboto/Roboto-Regular.ttf";

import RobotoBold from "assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";
import "./styles.css";
import PrintIcon from '@material-ui/icons/Print';
import Button from "components/CustomButtons/Button.js";
import squareBullet from "../../assets/img/squarebullet.png";
import bluerightarrow from "../../assets/img/bluerightarrow.png";
import arrowupgreen from "../../assets/img/arrowgreen.png";
import arrowdownred from "../../assets/img/arowred.png";
import arroworange from "../../assets/img/arroworange.png";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Chart from "react-apexcharts";
// Create styles
const styles = {
 
};
const useStyles = makeStyles(styles);

const MonthlyFundReport = ({monthlyFundReport,imageUrl,tmGenerateMonthlyFundReportRequest,tmGenerateMonthlyFundDetailsPdfDownloadRequest
}) =>{

    const classes = useStyles();
    const location = useLocation();
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [chartLabels,setChartLabels] = React.useState([]);
  const [chartData,setChartData] = React.useState([]);


  const [dataStatus, setDataStatus] = React.useState(false);
  const [showFundScoreList, setShowFundScoreList] = React.useState(false);
  const [showFundPortfolioSqftList, setShowFundPortfolioSqftList] = React.useState(false);
  const [showTopPerformingTenantsList,setShowTopPerformingTenantsList] = React.useState(false);
  const [showTopPerformingPropertiesList,setShowTopPerformingPropertiesList] = React.useState(false);
  const [showTopPerformingIndustriesList,setShowTopPerformingIndustriesList] = React.useState(false);
  const [showTopPerformingFundsList,setShowTopPerformingFundsList] = React.useState(false);

  const [showFundDetailsList,setShowFundDetailsList] = React.useState(false);
  const [noDataAvailableTopPerformingTenantsList,setNoDataAvailableTopPerformingTenantsList] = React.useState(false);
  const [noDataAvailableTopPerformingPropertiesList,setNoDataAvailableTopPerformingPropertiesList] = React.useState(false);
  const [noDataAvailableTopPerformingIndustyList,setNoDataAvailableTopPerformingIndustyList] = React.useState(false);
  const [noDataAvailableTopPerformingFundsList,setNoDataAvailableTopPerformingFundsList] = React.useState(false);


  const [noDataAvailableFundScoreList,setNoDataAvailableFundScoreList] = React.useState(false);
  const [noDataAvailableFundPortfolioSqftList,setNoDataAvailableFundPortfolioSqftList] = React.useState(false);
  const [noDataAvailableSuits,setNoDataAvailableSuits] = React.useState(false);
  const [showPieChart,setShowPieChart]=React.useState(false);

  const [noDataAvailableInactiveTenantList,setNoDataAvailableInactiveTenantList] = React.useState(false);
  const [noDataAvailablePositiveAlertsTenantList,setNoDataAvailablePositiveAlertsTenantList] = React.useState(false);
  const [noDataAvailableNegativeAlertsTenantList,setNoDataAvailableNegativeAlertsTenantList] = React.useState(false);
  const [noDataAvailableFundTopPerformingProperties,setNoDataAvailableFundTopPerformingProperties] = React.useState(false);
  const [noDataAvailableFundTopPerformingTenants,setNoDataAvailableFundTopPerformingTenants] = React.useState(false);
  const [noDataAvailableFundTopPerformingIndustries,setNoDataAvailableFundTopPerformingIndustries] = React.useState(false);
  const [noDataAvailableIncreasedCreditScoreList,setNoDataAvailableIncreasedCreditScoreList] = React.useState(false);
  const [noDataAvailableDecreasedCreditScoreList,setNoDataAvailableDecreasedCreditScoreList] = React.useState(false);

  const [showInactiveTenantList, setShowInactiveTenantList] = React.useState(false);
  const [showPositiveAlertsTenantList,setShowPositiveAlertsTenantList] = React.useState(false);
  const [showNegativeAlertsTenantList,setShowNegativeAlertsTenantList] = React.useState(false);
  const [showFundTopPerformingProperties,setShowFundTopPerformingProperties] = React.useState(false);
  const [showFundTopPerformingTenants,setShowFundTopPerformingTenants] = React.useState(false);
  const [showFundTopPerformingIndustries,setShowFundTopPerformingIndustries] = React.useState(false);

  const [showFundLowestScoringTable,setShowFundLowestScoringTable] = React.useState(false);
  const [noDataAvailableFundLowestScoringTable,setNoDataAvailableFundLowestScoringTable] = React.useState(false);
  const [showIncrementedCreditScoreList, setShowIncrementedCreditScoreList] = React.useState(false);
  const [showDecrementedCreditScoreList, setShowDecrementedCreditScoreList] = React.useState(false);

  const [showLowRiskCountPercentage, setShowLowRiskCountPercentage] = React.useState(false);
  const [showMediumLowRiskCountPercentage, setShowMediumLowRiskCountPercentage] = React.useState(false);
  const [showModerateHighRiskCountPercentage, setShowModerateHighRiskCountPercentage] = React.useState(false);
  const [showMediumHighRiskCountPercentage, setShowMediumHighRiskCountPercentage] = React.useState(false);
  const [showHighRiskCountPercentage, setShowHighRiskCountPercentage] = React.useState(false);

  const [noDataAvailableLowestScoringProperties,setNoDataAvailableLowestScoringProperties] = React.useState(false);
  const [noDataAvailableLowestScoringTenants,setNoDataAvailableLowestScoringTenants] = React.useState(false);
  const [noDataAvailableLowestScoringIndustries,setNoDataAvailableLowestScoringIndustries] = React.useState(false);
  const [showLowestScoringProperties,setShowLowestScoringProperties] = React.useState(false);
  const [showLowestScoringTenants,setShowLowestScoringTenants] = React.useState(false);
  const [showLowestScoringIndustries,setShowLowestScoringIndustries] = React.useState(false);
  const [showCautinaryUccList,setShowCautinaryUccList] = React.useState(false);
  const [noDataAvailableCautionaryUccList,setNoDataAvailableCautionaryUccList] = React.useState(false);

  const [showTaxLienList,setShowTaxLienList] = React.useState(false);
  const [noDataAvailableTaxLienList,setNoDataAvailableTaxLienList] = React.useState(false);

  const [showJudgementList,setShowJudgementList] = React.useState(false);
  const [noDataAvailableJudgementList,setNoDataAvailableJudgementList] = React.useState(false);

  const [showSuitsList,setShowSuitsList] = React.useState(false);
  const [noDataAvailableSuitsList,setNoDataAvailableSuitsList] = React.useState(false);

  const [showNewCautinaryUccList,setShowNewCautinaryUccList] = React.useState(false);
  const [noDataAvailableNewCautionaryUccList,setNoDataAvailableNewCautionaryUccList] = React.useState(false);

  const [showNewTaxLienList,setShowNewTaxLienList] = React.useState(false);
  const [noDataAvailableNewTaxLienList,setNoDataAvailableNewTaxLienList] = React.useState(false);

  const [showNewJudgementList,setShowNewJudgementList] = React.useState(false);
  const [noDataAvailableNewJudgementList,setNoDataAvailableNewJudgementList] = React.useState(false);

  const [showNewSuitsList,setShowNewSuitsList] = React.useState(false);
  const [noDataAvailableNewSuitsList,setNoDataAvailableNewSuitsList] = React.useState(false);

  const [showNoNewDerogFilings, setShowNoNewDerogFilings] = React.useState(false);
  const [showNewDerogFilingsCount, setShowNewDerogFilingsCount] = React.useState(false);

  // const [imageUrl, setImageUrl] = React.useState(location.state.imageUrl);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const todayNow = moment().format("MMMM YYYY"); 
  var currentYear  = moment().format('YYYY');
  const[showArepLogo,setShowArepLogo]=React.useState(false);
  const[showCarterLogo,setShowCarterLogo]=React.useState(false);
const[organisationId,setOrganisationId]=React.useState(location.state.organisationId);
const[fundId,setFundId]=React.useState(location.state.fundId);
const[fundName,setFundName]=React.useState(location.state.fundName);


React.useEffect(() => {

getMonthlyFundPdfReports(organisationId,fundId);

   setShowPieChart(true);
  setChartLabels(monthlyFundReport?.riskCounts?.labels);
  setChartData(monthlyFundReport?.riskCounts?.riskCounts);

  if(monthlyFundReport?.lowRiskCountPercentage > 0){
    setShowLowRiskCountPercentage(true);
  }else{
    setShowLowRiskCountPercentage(false);
  }

  if(monthlyFundReport?.mediumLowRiskCountPercentage > 0){
    setShowMediumLowRiskCountPercentage(true);
  }else{
    setShowMediumLowRiskCountPercentage(false);
  }

  if(monthlyFundReport?.moderateHighRiskCountPercentage > 0){
    setShowModerateHighRiskCountPercentage(true);
  }else{
    setShowModerateHighRiskCountPercentage(false);
  }

  if(monthlyFundReport?.mediumHighRiskCountPercentage > 0){
    setShowMediumHighRiskCountPercentage(true);
  }else{
    setShowMediumHighRiskCountPercentage(false);
  }

  if(monthlyFundReport?.highRiskCountPercentage > 0){
    setShowHighRiskCountPercentage(true);
  }else{
    setShowHighRiskCountPercentage(false);
  }
  
  //topPerforming

  if(monthlyFundReport?.topPerformingFundsList!==null || monthlyFundReport?.topPerformingFundsList!=[]){
    setShowTopPerformingFundsList(true);
    setNoDataAvailableTopPerformingFundsList(false);
  }

  if(monthlyFundReport?.topPerformingFundsList===null || monthlyFundReport?.topPerformingFundsList==[ ]){
    setShowTopPerformingFundsList(false);
    setNoDataAvailableTopPerformingFundsList(true);
  }

  if(monthlyFundReport?.topPerformingTenantsList!==null || monthlyFundReport?.topPerformingTenantsList!=[]){
      setShowTopPerformingTenantsList(true);
      setNoDataAvailableTopPerformingTenantsList(false);
    }

    if(monthlyFundReport?.topPerformingTenantsList===null || monthlyFundReport?.topPerformingTenantsList==[ ]){
      setShowTopPerformingTenantsList(false);
      setNoDataAvailableTopPerformingTenantsList(true);
    }
    
  if(monthlyFundReport?.topPerformingPropertiesList!==null || monthlyFundReport?.topPerformingPropertiesList!=[ ]){
      setShowTopPerformingPropertiesList(true);
      setNoDataAvailableTopPerformingPropertiesList(false);
    }
    if(monthlyFundReport?.topPerformingPropertiesList==null || monthlyFundReport?.topPerformingPropertiesList==[ ] || monthlyFundReport?.topPerformingPropertiesList?.length === 0){
      setShowTopPerformingPropertiesList(false);
      setNoDataAvailableTopPerformingPropertiesList(true);
    }    
   
  if(monthlyFundReport?.topPerformingIndustriesList!==null || monthlyFundReport?.topPerformingIndustriesList!=[]){

    setShowTopPerformingIndustriesList(true);
    setNoDataAvailableTopPerformingIndustyList(false);

    }
    if(monthlyFundReport?.topPerformingIndustriesList?.length===0 || monthlyFundReport?.topPerformingIndustriesList===null || monthlyFundReport?.topPerformingIndustriesList==[]){
      setShowTopPerformingIndustriesList(false);
      setNoDataAvailableTopPerformingIndustyList(true);
    }
    
   //positive and negative news list
    if(monthlyFundReport?.negativeNewsList!==null || monthlyFundReport?.negativeNewsList!=[ ]){
      setShowNegativeAlertsTenantList(true);
      setNoDataAvailableNegativeAlertsTenantList(false);
    }
    if(monthlyFundReport?.negativeNewsList?.length===0 || monthlyFundReport?.negativeNewsList===null || monthlyFundReport?.negativeNewsList==[ ]){
      setShowNegativeAlertsTenantList(false);
      setNoDataAvailableNegativeAlertsTenantList(true);
    }

    if(monthlyFundReport?.positiveNewsList!==null || monthlyFundReport?.positiveNewsList!=[ ]){
      setShowPositiveAlertsTenantList(true);
      setNoDataAvailablePositiveAlertsTenantList(false);
    }
    if(monthlyFundReport?.positiveNewsList?.length===0 || monthlyFundReport?.positiveNewsList===null || monthlyFundReport?.positiveNewsList==[ ]){
      setShowPositiveAlertsTenantList(false);
      setNoDataAvailablePositiveAlertsTenantList(true);
    }

    // creditscore incremented and decremented list
    if(monthlyFundReport?.creditScoreIncrementedList!==null || monthlyFundReport?.creditScoreIncrementedList!=[ ]){
      setShowIncrementedCreditScoreList(true);
      setNoDataAvailableIncreasedCreditScoreList(false);
    }
    if(monthlyFundReport?.creditScoreIncrementedList?.length===0 || monthlyFundReport?.creditScoreIncrementedList===null || monthlyFundReport?.creditScoreIncrementedList==[ ]){
      setShowIncrementedCreditScoreList(false);
      setNoDataAvailableIncreasedCreditScoreList(true);
    }

    if(monthlyFundReport?.creditScoreDecrementedList!=null || monthlyFundReport?.creditScoreDecrementedList!=[ ]){
      setShowDecrementedCreditScoreList(true);
      setNoDataAvailableDecreasedCreditScoreList(false);
    }
    if(monthlyFundReport?.creditScoreDecrementedList0?.length===0 || monthlyFundReport?.creditScoreDecrementedList==null || monthlyFundReport?.creditScoreDecrementedList==[ ]){
      setShowDecrementedCreditScoreList(false);
      setNoDataAvailableDecreasedCreditScoreList(true);
    }


    //lowestScoring
    if(monthlyFundReport?.lowestScoringPropertiesList?.length === 0){
      setShowLowestScoringProperties(false);
      setNoDataAvailableLowestScoringProperties(true);
   
    }
    if(monthlyFundReport?.lowestScoringPropertiesList?.length > 0){
      setShowLowestScoringProperties(true);
      setNoDataAvailableLowestScoringProperties(false);
    
    }
  
    if(monthlyFundReport?.lowestScoringTenantsList?.length > 0){
      setShowLowestScoringTenants(true);
      setNoDataAvailableLowestScoringTenants(false);
    
    }
    if(monthlyFundReport?.lowestScoringTenantsList?.length === 0){
      setShowLowestScoringTenants(false);
      setNoDataAvailableLowestScoringTenants(true);
    }
    
    if(monthlyFundReport?.lowestScoringIndustriesList?.length > 0){
      setShowLowestScoringIndustries(true);
      setNoDataAvailableLowestScoringIndustries(false);
     
    }
    if(monthlyFundReport?.lowestScoringIndustriesList?.length === 0){
      setShowLowestScoringIndustries(false);
      setNoDataAvailableLowestScoringIndustries(false);
    
    }

    if(monthlyFundReport?.cautionaryUccPropertyList != null ||  monthlyFundReport?.cautionaryUccPropertyList!=[ ] ){
  
        setShowCautinaryUccList(true);
        setNoDataAvailableCautionaryUccList(false);
      }
      if(monthlyFundReport?.cautionaryUccPropertyList?.length ===0 || monthlyFundReport?.cautionaryUccPropertyList ===null ||  monthlyFundReport?.cautionaryUccPropertyList?.length===0 || monthlyFundReport?.cautionaryUccPropertyList== [ ]){
        setShowCautinaryUccList(false);
        // setNoDataAvailableCautionaryUccList(true);
      }

      if(monthlyFundReport?.taxLiensPropertyList != null ||  monthlyFundReport?.taxLiensPropertyList!=[ ] ){
  
        setShowTaxLienList(true);
        setNoDataAvailableTaxLienList(false);
      }
      if(monthlyFundReport?.taxLiensPropertyList?.length ===0 || monthlyFundReport?.taxLiensPropertyList ===null ||  monthlyFundReport?.taxLiensPropertyList?.length===0 || monthlyFundReport?.taxLiensPropertyList== [ ]){
        setShowTaxLienList(false);
        // setNoDataAvailableTaxLienList(true);
      }

      if(monthlyFundReport?.judgementPropertyList != null ||  monthlyFundReport?.judgementPropertyList!=[ ] ){
  
        setShowJudgementList(true);
        setNoDataAvailableJudgementList(false);
      }
      if(monthlyFundReport?.judgementPropertyList?.length===0 || monthlyFundReport?.judgementPropertyList ===null ||  monthlyFundReport?.judgementPropertyList?.length===0 || monthlyFundReport?.judgementPropertyList== [ ]){
        setShowJudgementList(false);
        // setNoDataAvailableJudgementList(true);
      }

      if(monthlyFundReport?.suitsPropertyList != null ||  monthlyFundReport?.suitsPropertyList!=[ ] ){
  
        setShowSuitsList(true);
        // setNoDataAvailableSuitsList(false);
      }
      if(monthlyFundReport?.suitsPropertyList?.length===0 || monthlyFundReport?.suitsPropertyList ===null ||  monthlyFundReport?.suitsPropertyList?.length===0 || monthlyFundReport?.suitsPropertyList== [ ]){
        setShowSuitsList(false);
        // setNoDataAvailableSuitsList(true);
      }
      if(monthlyFundReport?.cautionaryUccPropertyList?.length===0 && monthlyFundReport?.taxLiensPropertyList?.length===0 && monthlyFundReport?.judgementPropertyList?.length===0 && monthlyFundReport?.suitsPropertyList?.length===0){
        // setShowNoNewDerogFilings(true);
        // setShowNewDerogFilingsCount(false);
        setShowSuitsList(false);
        setShowJudgementList(false);
        setShowTaxLienList(false);
        setShowCautinaryUccList(false);
      }else{
        // setShowNoNewDerogFilings(false);
        // setShowNewDerogFilingsCount(true);
      }
     //new derog filings
     if(monthlyFundReport?.newCautionaryUccPropertyList?.length===0){     
       
      setShowNewCautinaryUccList(false);
      // setNoDataAvailableNewCautionaryUccList(true);
      // setShowNoNewDerogFilings(true);
  
      }
      else{
   
        setShowNewCautinaryUccList(true);
        setNoDataAvailableNewCautionaryUccList(false);
        setShowNoNewDerogFilings(false);
      }
        
    
      if(monthlyFundReport?.newTaxLiensPropertyList?.length===0){    
    
        setShowNewTaxLienList(false);
        // setNoDataAvailableNewTaxLienList(true);
        // setShowNoNewDerogFilings(true);   
   
        }else{
          setShowNewTaxLienList(true);
          setNoDataAvailableNewTaxLienList(false);
          setShowNoNewDerogFilings(false);
        }
      
    
        if(monthlyFundReport?.newJudgementPropertyList?.length===0){

          setShowNewJudgementList(false);
          // setNoDataAvailableNewJudgementList(true);
          // setShowNoNewDerogFilings(true);

          }else{
          setShowNewJudgementList(true);
          setNoDataAvailableNewJudgementList(false);
          setShowNoNewDerogFilings(false);
        
          }
          
          if(monthlyFundReport?.newSuitsPropertyList?.length===0){
      
            setShowNewSuitsList(false);
            // setNoDataAvailableNewSuitsList(true);
            // setShowNoNewDerogFilings(true);
          
            }else{
                
            setShowNewSuitsList(true);
            setNoDataAvailableNewSuitsList(false);
            setShowNoNewDerogFilings(false);
            }

            if(monthlyFundReport?.newCautionaryUccPropertyList?.length===0 && monthlyFundReport?.newTaxLiensPropertyList?.length===0 && monthlyFundReport?.newJudgementPropertyList?.length===0 && monthlyFundReport?.newSuitsPropertyList?.length===0){
              setShowNoNewDerogFilings(true);
              setShowNewDerogFilingsCount(false);
              setShowNewSuitsList(false);
              setShowNewJudgementList(false);
              setShowNewTaxLienList(false);
              setShowNewCautinaryUccList(false);
            }else{
              setShowNoNewDerogFilings(false);
              setShowNewDerogFilingsCount(true);
            }
  setTimeout(() => {
    setDataStatus(true);
   
    }, 2000);
    }, [dataStatus]);

const getMonthlyFundPdfReports = async (organisationId,fundId) => {
await tmGenerateMonthlyFundReportRequest(organisationId,fundId);
};


const chartOptions = {
    options: {
      chart: {
        type: "pie",
      },
      labels: monthlyFundReport?.riskCounts?.labels,
      legend: {
        position: "bottom",
      },
      colors: ['#4caf50', '#32cd32', '#ffdf00', '#ff9800', 'red'], // Define colors inside options
    },
    series: monthlyFundReport?.riskCounts?.riskCounts, // ApexCharts expects data in series as an array
  };
  const handleClickDownload = (value) => {

    tmGenerateMonthlyFundDetailsPdfDownloadRequest(organisationId,fundId,fundName)
  
  };

if(dataStatus){
return(
<div>
  <Card>
  <div align="right" style={{marginRight: "35px",marginTop:"10px"}}>
    <Button onClick={handleClickDownload}  color="info" size="sm" className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}>
    <PrintIcon/>  &nbsp;&nbsp;Print  
    </Button>
  </div>

<div style={{fontSize:"25px",marginTop:"20px",color:megaColor,fontWeight:"bold",textAlign:"center"}}>Monthly {fundName} Summary</div>
<div style={{fontSize:"25px",marginTop:"20px",color:megaColor,textAlign:"center"}}>{todayNow}</div>

<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}}>            
 <div class="table-container-tenant-summary">
 {fundName} has an overall score of {monthlyFundReport?.fundScore} (on a scale of 0-500).
 </div>
</div>
{/* graph */}
<div style={{display:"flex",flexDirection:"row"}}>
  <div class="graph-card">
     <Chart options={chartOptions.options} series={chartOptions.series} type="pie" width={280} height={290} align="center"/>
  </div>
  <div class="arrow">
  <img class="arrow2" src={bluerightarrow} alt="Arrow" />
  </div>
  <div class="graph-card1">
  <p style={{ fontSize: "15px", color: "#000000", marginTop: 5,fontWeight:"bold" }}>{fundName} has:</p>

        {showLowRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 7 }}>
           <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {monthlyFundReport?.lowRiskCountPercentage}% of its tenants with a Low Risk Score (401 and higher)
            </p>  

            </div>
        )}

        {showMediumLowRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
           <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {monthlyFundReport?.mediumLowRiskCountPercentage}% of its tenants with a Medium Low Risk Score (301 to 400)
            </p>  

            </div>
        )}

        {showModerateHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
            <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {monthlyFundReport?.moderateHighRiskCountPercentage}% of its tenants with a Moderately High Risk Score (201 to 300)
            </p>  

            </div>
        )}

        {showMediumHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
             <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {monthlyFundReport?.mediumHighRiskCountPercentage}% of its tenants with a Medium High Risk Score (101 to 200)
            </p>  

            </div>
        )}
        {showHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {monthlyFundReport?.highRiskCountPercentage}% of its tenants with a very High Risk Score (0 to 100)
            </p>  

            </div>
        )}
</div>
</div>

{/* Top performing property list */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Top Performing Properties</div>
</header>
      {showTopPerformingPropertiesList && (
        <table class="table-income">
          <thead class="table-income-thead">
            <tr>
                <td >Properties</td>
                <td style={{textAlign:"center"}}>Property Score</td>
                <td style={{textAlign:"center"}}>Portfolio (%)</td>
                <td style={{textAlign:"center"}}>Monthly Change</td>
        
            </tr>
          </thead>
           <tbody>
           {monthlyFundReport?.topPerformingPropertiesList !== "" &&
              monthlyFundReport?.topPerformingPropertiesList?.map((topPerformingProperty, i) => (              <tr>
                <td style={{whiteSpace:"nowrap", textAlign: "left"}}>{topPerformingProperty.name}</td>
                <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingProperty.currentScore}</td>           
                <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingProperty.percentageOfPortfolio}%</td>
                <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingProperty.monthlyChange==="up" ? <img class="monthly-arrow" src={arrowupgreen} alt="Arrow" /> : topPerformingProperty.monthlyChange==='down' ? <img class="monthly-arrow" src={arrowdownred} alt="Arrow" /> : <img class="monthly-arrow" src={arroworange} alt="Arrow" />}</td>
              </tr>
            ))}
            </tbody>
         
        </table>
      )}
      {noDataAvailableTopPerformingPropertiesList && (
        <p style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>
          There are no properties which have a score of more than 300 for the current month.
        </p>
      )}
</div>

 {/* Lowest Performing Properties */}
 <div style={{ marginLeft: "35px", marginRight: "35px" }}>
        <header className="locationheader-tenantsummary">
          <div className="locationheadercolor-tenantsummary">Lowest Performing Properties</div>
        </header>
        {showLowestScoringProperties && (
          <table className="table-income">
            <thead className="table-income-thead">
              <tr>
                <td>Properties</td>
                <td style={{ textAlign: "center" }}>Property Score</td>
                <td style={{ textAlign: "center" }}>Portfolio (%)</td>
                <td style={{ textAlign: "center" }}>Monthly Change</td>
              </tr>
            </thead>
            <tbody>
              {monthlyFundReport?.lowestScoringPropertiesList !== "" &&
                monthlyFundReport?.lowestScoringPropertiesList?.map((lowestScoringProperty, i) => (
                  <tr key={i}>
                    <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>{lowestScoringProperty.name}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{lowestScoringProperty.currentScore}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{lowestScoringProperty.percentageOfPortfolio}%</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                      {lowestScoringProperty.monthlyChange === "up" ? (
                        <img className="monthly-arrow" src={require("assets/img/arrowgreen.png")} alt="Arrow" />
                      ) : lowestScoringProperty.monthlyChange === "down" ? (
                        <img className="monthly-arrow" src={require("assets/img/arowred.png")} alt="Arrow" />
                      ) : (
                        <img className="monthly-arrow" src={require("assets/img/arroworange.png")} alt="Arrow" />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {noDataAvailableLowestScoringProperties && (
          <p style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>
            There are no properties which have a score of less than 300 for the current month.
          </p>
        )}
  </div>

  {/* Top Performing Tenants */}
  <div style={{ marginLeft: "35px", marginRight: "35px" }}>
        <header className="locationheader-tenantsummary">
          <div className="locationheadercolor-tenantsummary">Top Performing Tenants</div>
        </header>
        {showTopPerformingTenantsList && (
          <table className="table-income">
            <thead className="table-income-thead">
              <tr>
                <td>Tenants</td>
                <td style={{ textAlign: "center" }}>Tenant Score</td>
                <td style={{ textAlign: "center" }}>Portfolio (%)</td>
                <td style={{ textAlign: "center" }}>Monthly Change</td>
              </tr>
            </thead>
            <tbody>
              {monthlyFundReport?.topPerformingTenantsList !== "" &&
                monthlyFundReport?.topPerformingTenantsList?.map((topPerformingTenant, i) => (
                  <tr key={i}>
                    <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>{topPerformingTenant.name}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{topPerformingTenant.currentScore}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{topPerformingTenant.percentageOfPortfolio}%</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                      {topPerformingTenant.monthlyChange === "up" ? (
                        <img className="monthly-arrow" src={require("assets/img/arrowgreen.png")} alt="Arrow" />
                      ) : topPerformingTenant.monthlyChange === "down" ? (
                        <img className="monthly-arrow" src={require("assets/img/arowred.png")} alt="Arrow" />
                      ) : (
                        <img className="monthly-arrow" src={require("assets/img/arroworange.png")} alt="Arrow" />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {noDataAvailableTopPerformingTenantsList && (
          <p style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>
            There are no tenants which have a score of more than 300 for the current month.
          </p>
        )}
</div>

   {/* Lowest Performing Tenants */}
   <div style={{ marginLeft: "35px", marginRight: "35px" }}>
        <header className="locationheader-tenantsummary">
          <div className="locationheadercolor-tenantsummary">Lowest Performing Tenants</div>
        </header>
        {showLowestScoringTenants && (
          <table className="table-income">
            <thead className="table-income-thead">
              <tr>
                <td>Tenants</td>
                <td style={{ textAlign: "center" }}>Tenant Score</td>
                <td style={{ textAlign: "center" }}>Portfolio (%)</td>
                <td style={{ textAlign: "center" }}>Monthly Change</td>
              </tr>
            </thead>
            <tbody>
              {monthlyFundReport?.lowestScoringTenantsList !== "" &&
                monthlyFundReport?.lowestScoringTenantsList?.map((lowestScoringTenant, i) => (
                  <tr key={i}>
                    <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>{lowestScoringTenant.name}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{lowestScoringTenant.currentScore}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{lowestScoringTenant.percentageOfPortfolio}%</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                      {lowestScoringTenant.monthlyChange === "up" ? (
                        <img className="monthly-arrow" src={require("assets/img/arrowgreen.png")} alt="Arrow" />
                      ) : lowestScoringTenant.monthlyChange === "down" ? (
                        <img className="monthly-arrow" src={require("assets/img/arowred.png")} alt="Arrow" />
                      ) : (
                        <img className="monthly-arrow" src={require("assets/img/arroworange.png")} alt="Arrow" />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {noDataAvailableLowestScoringTenants && (
          <p style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>
            There are no tenants which have a score less than 300 for the current month.
          </p>
        )}
</div>

 {/* Top Performing Industries */}
 <div style={{ marginLeft: "35px", marginRight: "35px" }}>
        <header className="locationheader-tenantsummary">
          <div className="locationheadercolor-tenantsummary">Top Performing Industries</div>
        </header>
        {showTopPerformingIndustriesList && (
          <table className="table-income">
            <thead className="table-income-thead">
              <tr>
                <td>Industries</td>
                <td style={{ textAlign: "center" }}>Industry Score</td>
                <td style={{ textAlign: "center" }}>Portfolio (%)</td>
                <td style={{ textAlign: "center" }}>Monthly Change</td>
              </tr>
            </thead>
            <tbody>
              {monthlyFundReport?.topPerformingIndustriesList !== "" &&
                monthlyFundReport?.topPerformingIndustriesList?.map((topPerformingIndustry, i) => (
                  <tr key={i}>
                    <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>{topPerformingIndustry.name}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{topPerformingIndustry.currentScore}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{topPerformingIndustry.percentageOfPortfolio}%</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                      {topPerformingIndustry.monthlyChange === "up" ? (
                        <img className="monthly-arrow" src={require("assets/img/arrowgreen.png")} alt="Arrow" />
                      ) : topPerformingIndustry.monthlyChange === "down" ? (
                        <img className="monthly-arrow" src={require("assets/img/arowred.png")} alt="Arrow" />
                      ) : (
                        <img className="monthly-arrow" src={require("assets/img/arroworange.png")} alt="Arrow" />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {noDataAvailableTopPerformingIndustyList && (
          <p style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>
            No Data Available for top performing industries...
          </p>
        )}
      </div>

 {/* Lowest Performing Industries */}
 <div style={{ marginLeft: "35px", marginRight: "35px" }}>
        <header className="locationheader-tenantsummary">
          <div className="locationheadercolor-tenantsummary">Lowest Performing Industries</div>
        </header>
        {showLowestScoringIndustries && (
          <table className="table-income">
            <thead className="table-income-thead">
              <tr>
                <td>Industries</td>
                <td style={{ textAlign: "center" }}>Industry Score</td>
                <td style={{ textAlign: "center" }}>Portfolio (%)</td>
                <td style={{ textAlign: "center" }}>Monthly Change</td>
              </tr>
            </thead>
            <tbody>
              {monthlyFundReport?.lowestScoringIndustriesList !== "" &&
                monthlyFundReport?.lowestScoringIndustriesList?.map((lowestScoringIndustry, i) => (
                  <tr key={i}>
                    <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>{lowestScoringIndustry.name}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{lowestScoringIndustry.currentScore}</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{lowestScoringIndustry.percentageOfPortfolio}%</td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                      {lowestScoringIndustry.monthlyChange === "up" ? (
                        <img className="monthly-arrow" src={require("assets/img/arrowgreen.png")} alt="Arrow" />
                      ) : lowestScoringIndustry.monthlyChange === "down" ? (
                        <img className="monthly-arrow" src={require("assets/img/arowred.png")} alt="Arrow" />
                      ) : (
                        <img className="monthly-arrow" src={require("assets/img/arroworange.png")} alt="Arrow" />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {noDataAvailableLowestScoringIndustries && (
          <p style={{fontSize:"16px",marginTop:17,fontWeight:900,textAlign:"center"}}>
            No Data Available for lowest scoring industries...
          </p>
        )}
</div>


{/* creditscore */}
<div  class="section-content-title-tenantsummary" >
        Credit Score Changes
</div>

<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
<div class="table-container-tenant-summary">{monthlyFundReport.creditScoreText}</div>
</div>

  {/* Tenants with Increased Credit Score */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
  <header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Entities with Increased Credit Score</div>
  </header>     
   {showIncrementedCreditScoreList && (
      <table class="table-income">
        <thead class="table-income-thead"> 
            <tr>
              <td>Tenants</td>
              <td style={{ textAlign: "center" }}>Previous Score</td>
              <td style={{ textAlign: "center" }}>Current Score</td>
            </tr>
          </thead>
          <tbody>
            {monthlyFundReport?.creditScoreIncrementedList !== "" &&
              monthlyFundReport?.creditScoreIncrementedList?.map((increment, i) => (
                <tr key={i}>
                  <td>{increment.name}</td>
                  <td style={{ textAlign: "center" }}>{increment.prevScore}</td>
                  <td style={{ textAlign: "center" }}>{increment.currScore} ({increment.percentage}%)</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {noDataAvailableIncreasedCreditScoreList && (
        <p style={{fontSize:"16px",marginTop:17,fontWeight:900,textAlign:"center"}}>
          There are no tenants whose credit score increased by 50 basis points in the current month.
        </p>
      )}
</div>

      {/* Tenants with Decreased Credit Score */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
       <header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Entities with Decreased Credit Score</div>
       </header>      
       {showDecrementedCreditScoreList && (
        <table class="table-income">
          <thead class="table-income-thead"> 
            <tr>
              <td>Tenants</td>
              <td style={{ textAlign: "center" }}>Previous Score</td>
              <td style={{ textAlign: "center" }}>Current Score</td>
            </tr>
          </thead>
          <tbody>
            {monthlyFundReport?.creditScoreDecrementedList !== "" &&
              monthlyFundReport?.creditScoreDecrementedList?.map((decrement, i) => (
                <tr key={i}>
                  <td>{decrement.name}</td>
                  <td style={{ textAlign: "center" }}>{decrement.prevScore}</td>
                  <td style={{ textAlign: "center" }}>{decrement.currScore} ({decrement.percentage}%)</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {noDataAvailableDecreasedCreditScoreList && (
        <p style={{fontSize:"16px",marginTop:17,fontWeight:900,textAlign:"center"}}> 
           There are no tenants whose credit score decreased by 50 basis points in the current month.
        </p>
      )}
 </div>  

{/* Derogatory  */}
<div  class="section-content-title-tenantsummary" >
Derogatory Summary
</div>

{showNoNewDerogFilings &&
<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}}>            
 <div class="table-container-tenant-summary">There are no new derogatory filings for the current month.    </div>
</div>}

{showNewDerogFilingsCount &&<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">{monthlyFundReport.newDerogText}</div>
</div>}


 {/* New Cautionary UCC Filings */}
 <div style={{marginLeft:"35px",marginRight:"35px"}}>
 {showNewCautinaryUccList && (
        <>
        <header class="locationheader-tenantsummary">
                <div class="locationheadercolor-tenantsummary">Cautionary UCC Filings</div>
        </header>
          {monthlyFundReport.newCautionaryUccPropertyList !== "" &&
            monthlyFundReport.newCautionaryUccPropertyList.map((property, i) => (
              <div key={i}>
                <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px",fontWeight:"bold" }}>
                  Property: {property.propertyName}
                </h3>
                {property.cautionaryUccAndTenantDTOList.map((tenant, j) => (
                  <div key={j}>
                    <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px",fontWeight:"bold" }}>Tenant: {tenant.tenantName}</h4>
                    <table class="table-income">
                    <thead class="table-income-thead"> 
                        <tr>
                          <th>Date Filed</th>
                          <th>Jurisdiction</th>
                          <th>Secured Party Information</th>
                          <th>Collateral</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tenant.cautionaryUccDetailsList.map((details, k) => (
                          <tr key={k}>
                            <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                            <td>{details.jurisdiction}</td>
                            <td>{details.securedPartyName}%</td>
                            <td>{details.collateral}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            ))}
        </>
      )}
</div>

{/* New Taxlien Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
        {showNewTaxLienList && (
          <>
            <header className="locationheader-tenantsummary">
              <div className="locationheadercolor-tenantsummary">TaxLien Filings</div>
            </header>
            {monthlyFundReport.newTaxLiensPropertyList !== "" &&
              monthlyFundReport.newTaxLiensPropertyList.map((property, i) => (
                <div key={i}>
                  <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px",fontWeight:"bold" }}>
                    Property: {property.propertyName}
                  </h3>
                  {property.taxLiensTenantDTOList.map((tenant, j) => (
                    <div key={j}>
                      <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px",fontWeight:"bold" }}>Tenant: {tenant.tenantName}</h4>
                      <table class="table-income">
                      <thead class="table-income-thead"> 
                          <tr>
                            <th>Date Filed</th>
                            <th>Filing Type</th>
                            <th>Filing Number</th>
                            <th>Amount</th>
                            <th>Jurisdiction</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tenant.taxLienDerogDetailsList.map((details, k) => (
                            <tr key={k}>
                              <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                              <td>{details.filingType}</td>
                              <td>{details.filingNumber}</td>
                              <td>{details.amount}</td>
                              <td>{details.jurisdiction}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              ))}
          </>
        )}
       
      </div>

{/* New Judgement Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
        {showNewJudgementList && (
          <>
            <header className="locationheader-tenantsummary">
              <div className="locationheadercolor-tenantsummary">Judgement Filings</div>
            </header>
            {monthlyFundReport.newJudgementPropertyList !== "" &&
              monthlyFundReport.newJudgementPropertyList.map((property, i) => (
                <div key={i}>
                  <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px", fontWeight: "bold" }}>
                    Property: {property.propertyName}
                  </h3>
                  {property.judgementTenantDTOList.map((tenant, j) => (
                    <div key={j}>
                      <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px", fontWeight: "bold" }}>Tenant: {tenant.tenantName}</h4>
                      <table className="table-income">
                        <thead className="table-income-thead">
                          <tr>
                            <th>Date Filed</th>
                            <th>Filing Type</th>
                            <th>Case Number</th>
                            <th>Amount</th>
                            <th>Plaintiff</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tenant.judgementDerogDetailsList.map((details, k) => (
                            <tr key={k}>
                              <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                              <td>{details.filingType}</td>
                              <td>{details.filingNumber}</td>
                              <td>{details.amount}</td>
                              <td>{details.plaintiff}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              ))}
          </>
        )}
</div>

{/* New Civil Suits Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showNewSuitsList && (
    <>
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Civil Suits Filings</div>
      </header>
      {monthlyFundReport.newSuitsPropertyList !== "" &&
        monthlyFundReport.newSuitsPropertyList.map((property, i) => (
          <div key={i}>
            <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px", fontWeight: "bold" }}>
              Property: {property.propertyName}
            </h3>
            {property.suitsTenantDTOList.map((tenant, j) => (
              <div key={j}>
                <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px", fontWeight: "bold" }}>
                  Tenant: {tenant.tenantName}
                </h4>
                <table className="table-income">
                  <thead className="table-income-thead">
                    <tr>
                      <th>Date Filed</th>
                      <th>Filing Type</th>
                      <th>Case Number</th>
                      <th>Amount</th>
                      <th>Plaintiff</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenant.suitsDerogDetailsList.map((details, k) => (
                      <tr key={k}>
                        <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                        <td>{details.filingType}</td>
                        <td>{details.filingNumber}</td>
                        <td>{details.amount}</td>
                        <td>{details.plaintiff}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">{monthlyFundReport.derogText}</div>
</div>


{/* Cautionary UCC Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showCautinaryUccList && (
    <>
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Cautionary UCC Filings</div>
      </header>
      {monthlyFundReport.cautionaryUccPropertyList !== "" &&
        monthlyFundReport.cautionaryUccPropertyList.map((property, i) => (
          <div key={i}>
            <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px", fontWeight: "bold" }}>
              Property: {property.propertyName}
            </h3>
            {property.cautionaryUccAndTenantDTOList.map((tenant, j) => (
              <div key={j}>
                <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px", fontWeight: "bold" }}>
                  Tenant: {tenant.tenantName}
                </h4>
                <table className="table-income">
                  <thead className="table-income-thead">
                    <tr>
                      <th>Date Filed</th>
                      <th>Jurisdiction</th>
                      <th>Secured Party Information</th>
                      <th>Collateral</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenant.cautionaryUccDetailsList.map((details, k) => (
                      <tr key={k}>
                        <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                        <td>{details.jurisdiction}</td>
                        <td>{details.securedPartyName}</td>
                        <td>{details.collateral}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* Tax Lien Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showTaxLienList && (
    <>
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Tax Lien Filings</div>
      </header>
      {monthlyFundReport.taxLiensPropertyList !== "" &&
        monthlyFundReport.taxLiensPropertyList.map((property, i) => (
          <div key={i}>
            <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px", fontWeight: "bold" }}>
              Property: {property.propertyName}
            </h3>
            {property.taxLiensTenantDTOList.map((tenant, j) => (
              <div key={j}>
                <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px", fontWeight: "bold" }}>
                  Tenant: {tenant.tenantName}
                </h4>
                <table className="table-income">
                  <thead className="table-income-thead">
                    <tr>
                      <th>Date Filed</th>
                      <th>Filing Type</th>
                      <th>Filing Number</th>
                      <th>Amount</th>
                      <th>Jurisdiction</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenant.taxLienDerogDetailsList.map((details, k) => (
                      <tr key={k}>
                        <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                        <td>{details.filingType}</td>
                        <td>{details.filingNumber}</td>
                        <td>{details.amount}</td>
                        <td>{details.jurisdiction}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* Judgment Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showJudgementList && (
    <>
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Judgment Filings</div>
      </header>
      {monthlyFundReport.judgementPropertyList !== "" &&
        monthlyFundReport.judgementPropertyList.map((property, i) => (
          <div key={i}>
            <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px", fontWeight: "bold" }}>
              Property: {property.propertyName}
            </h3>
            {property.judgementTenantDTOList.map((tenant, j) => (
              <div key={j}>
                <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px", fontWeight: "bold" }}>
                  Tenant: {tenant.tenantName}
                </h4>
                <table className="table-income">
                  <thead className="table-income-thead">
                    <tr>
                      <th>Date Filed</th>
                      <th>Filing Type</th>
                      <th>Case Number</th>
                      <th>Amount</th>
                      <th>Plaintiff</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenant.judgementDerogDetailsList.map((details, k) => (
                      <tr key={k}>
                        <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                        <td>{details.filingType}</td>
                        <td>{details.filingNumber}</td>
                        <td>{details.amount}</td>
                        <td>{details.plaintiff}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>

{/* Civil Suits Filings */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
  {showSuitsList && (
    <>
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Civil Suits Filings</div>
      </header>
      {monthlyFundReport.suitsPropertyList !== "" &&
        monthlyFundReport.suitsPropertyList.map((property, i) => (
          <div key={i}>
            <h3 style={{ fontSize: "16px", color: "#000000", marginTop: "17px", marginBottom: "5px", fontWeight: "bold" }}>
              Property: {property.propertyName}
            </h3>
            {property.suitsTenantDTOList.map((tenant, j) => (
              <div key={j}>
                <h4 style={{ fontSize: "14px", color: "#000000", marginTop: "5px", fontWeight: "bold" }}>
                  Tenant: {tenant.tenantName}
                </h4>
                <table className="table-income">
                  <thead className="table-income-thead">
                    <tr>
                      <th>Date Filed</th>
                      <th>Filing Type</th>
                      <th>Case Number</th>
                      <th>Amount</th>
                      <th>Plaintiff</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenant.suitsDerogDetailsList.map((details, k) => (
                      <tr key={k}>
                        <td>{Moment(details.filedDate).format("MM-DD-YYYY")}</td>
                        <td>{details.filingType}</td>
                        <td>{details.filingNumber}</td>
                        <td>{details.amount}</td>
                        <td>{details.plaintiff}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
    </>
  )}
</div>


{/* News Summary */}

<div  class="section-content-title-tenantsummary" >
       News Summary
</div>
<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">{monthlyFundReport.newsText}</div>
</div>

{/* Positive news alerts */}
<div style={{ marginLeft: "35px", marginRight: "35px" }}>
      {/* Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Tenants with Positive News Alerts</div>
      </header>

      {/* Positive Alerts Table */}
      {showPositiveAlertsTenantList && (
        <table className="table-income">
          <thead className="table-income-thead">
            <tr>
              <td>Name</td>
              <td style={{ textAlign: "left" }}>Category</td>
            </tr>
          </thead>
          <tbody>
            {monthlyFundReport?.positiveNewsList &&
              monthlyFundReport.positiveNewsList.map((positiveNews, i) => (
                <tr key={i}>
                  <td>{positiveNews.tenantName}</td>
                  <td style={{ textAlign: "left" }}>{positiveNews.category.join(", ")}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {/* No Data Available */}
      {noDataAvailablePositiveAlertsTenantList && (
        <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
          No Data Available for positive news alerts...
        </p>
      )}
</div>

{/* Negative news alerts */}
<div style={{ marginLeft: "35px", marginRight: "35px",marginBottom:"20px"}}>
      {/* Header */}
      <header className="locationheader-tenantsummary">
        <div className="locationheadercolor-tenantsummary">Tenants with Negative News Alerts</div>
      </header>

      {/* Negative Alerts Table */}
      {showNegativeAlertsTenantList && (
        <table className="table-income">
          <thead className="table-income-thead">
            <tr>
              <td>Name</td>
              <td style={{ textAlign: "left" }}>Category</td>
            </tr>
          </thead>
          <tbody>
            {monthlyFundReport?.negativeNewsList &&
              monthlyFundReport.negativeNewsList.map((negativeNews, i) => (
                <tr key={i}>
                  <td>{negativeNews.tenantName}</td>
                  <td style={{ textAlign: "left" }}>{negativeNews.category.join(", ")}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {/* No Data Available */}
      {noDataAvailableNegativeAlertsTenantList && (
        <p style={{ fontSize: "16px", marginTop: 20, fontWeight: 900, textAlign: "center" }}>
          No Data Available for negative news alerts...
        </p>
      )}
</div>

  </Card>
</div>
  
);
 }else{
 return(
 
 <div align="center">
 <Loader
     type="ThreeDots"
     color="#00BFFF"
     height={500}
     width={100}
     timeout={3000} //3 secs
   /></div>
   )
 }
 }
  
MonthlyFundReport.propTypes = {

  loading: PropTypes.bool.isRequired,
  tmGenerateMonthlyFundReportRequest: PropTypes.func.isRequired,
  tmGenerateMonthlyFundDetailsPdfDownloadRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  monthlyFundReport: state.tenantmonitoring.monthlyFundReport,
  });              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonthlyFundReport);