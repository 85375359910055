import React from 'react';

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor
} from "assets/jss/material-dashboard-pro-react.js";

import Moment from 'moment';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Paper from "@material-ui/core/Paper";
// import roboto from "assets/roboto/Roboto-Regular.ttf";

import RobotoBold from "assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";

import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";
import { Card } from '@material-ui/core';
import "./styles.css";
import PrintIcon from '@material-ui/icons/Print';
import Button from "components/CustomButtons/Button.js";
import squareBullet from "../../assets/img/squarebullet.png";
import bluerightarrow from "../../assets/img/bluerightarrow.png";
import arrowupgreen from "../../assets/img/arrowgreen.png";
import arrowdownred from "../../assets/img/arowred.png";
import arroworange from "../../assets/img/arroworange.png";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

// Create classes
const styles = {
 
};

const useStyles = makeStyles(styles);

const PropertyMonthlyPdfReport = ({organisationName,generateMonthlyPropertyDetailsPdfReport,riskListGraphMonthlyReport,
  dataUrlList,imageUrl,tmGenerateMonthlyPropertyDetailsPdfReportRequest,tmGenerateMonthlyPropertyDetailsPdfDownloadRequest
}) =>{

  const location = useLocation();

  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [chartLabels,setChartLabels] = React.useState([]);
  const [chartData,setChartData] = React.useState([]);

  const classes = useStyles();
  const [dataStatus, setDataStatus] = React.useState(false);
  const [showTopPerformingTenantsList,setShowTopPerformingTenantsList] = React.useState(false);
  const [showTopPerformingIndustriesList,setShowTopPerformingIndustriesList] = React.useState(false);
  const [showCautinaryUccList,setShowCautinaryUccList] = React.useState(false);

  const[showPieChart,setShowPieChart]=React.useState(false);

  const [noDataAvailableTopPerformingTenantsList,setNoDataAvailableTopPerformingTenantsList] = React.useState(false);
  const [noDataAvailableTopPerformingIndustriesList,setNoDataAvailableTopPerformingIndustriesList] = React.useState(false);
 

  const [noDataAvailablePropertyNotification,setNoDataAvailablePropertyNotification] = React.useState(false);
  const [showPropertyNotification,setShowPropertyNotification] = React.useState(false);

  const [noDataAvailableDerogCount,setNoDataAvailableDerogCount] = React.useState(false);
  const [showDerogCount,setShowDerogCount] = React.useState(false);

  const [noDataAvailableLiensCount,setNoDataAvailableLiensCount] = React.useState(false);
  const [showLiensCount,setShowLiensCount] = React.useState(false);

  const [noDataAvailableJudgmentCount,setNoDataAvailableJudgmentCount] = React.useState(false);
  const [showJudgementCount,setShowJudgementCount] = React.useState(false);

  const [noDataAvailableSuitsCount,setNoDataAvailableSuitsCount] = React.useState(false);
  const [showSuitsCount,setShowSuitsCount] = React.useState(false);

  const [noDataAvailableCautionaryUccCount,setNoDataAvailableCautionaryUccCount] = React.useState(false);
  const [showCautionaryUccCount,setShowCautionaryUccCount] = React.useState(false);
  const [noDataAvailableCautionaryUccList,setNoDataAvailableCautionaryUccList] = React.useState(false);

  const[showLineChart,setShowLineChart]=React.useState(false);

  const [showLowRiskCountPercentage, setShowLowRiskCountPercentage] = React.useState(false);
  const [showMediumLowRiskCountPercentage, setShowMediumLowRiskCountPercentage] = React.useState(false);
  const [showModerateHighRiskCountPercentage, setShowModerateHighRiskCountPercentage] = React.useState(false);
  const [showMediumHighRiskCountPercentage, setShowMediumHighRiskCountPercentage] = React.useState(false);
  const [showHighRiskCountPercentage, setShowHighRiskCountPercentage] = React.useState(false);

  const [noDataAvailableLowestScoringProperties,setNoDataAvailableLowestScoringProperties] = React.useState(false);
  const [noDataAvailableLowestScoringTenants,setNoDataAvailableLowestScoringTenants] = React.useState(false);
  const [noDataAvailableLowestScoringIndustries,setNoDataAvailableLowestScoringIndustries] = React.useState(false);
  const [showLowestScoringProperties,setShowLowestScoringProperties] = React.useState(false);
  const [showLowestScoringTenants,setShowLowestScoringTenants] = React.useState(false);
  const [showLowestScoringIndustries,setShowLowestScoringIndustries] = React.useState(false);

  const [showIncrementedCreditScoreList, setShowIncrementedCreditScoreList] = React.useState(false);
  const [showDecrementedCreditScoreList, setShowDecrementedCreditScoreList] = React.useState(false);
  const [showPositiveAlertsTenantList,setShowPositiveAlertsTenantList] = React.useState(false);
  const [showNegativeAlertsTenantList,setShowNegativeAlertsTenantList] = React.useState(false);

  const [noDataAvailablePositiveAlertsTenantList,setNoDataAvailablePositiveAlertsTenantList] = React.useState(false);
  const [noDataAvailableNegativeAlertsTenantList,setNoDataAvailableNegativeAlertsTenantList] = React.useState(false);
  const [noDataAvailableIncreasedCreditScoreList,setNoDataAvailableIncreasedCreditScoreList] = React.useState(false);
  const [noDataAvailableDecreasedCreditScoreList,setNoDataAvailableDecreasedCreditScoreList] = React.useState(false);
  const [noDataAvailableTopPerformingIndustyList,setNoDataAvailableTopPerformingIndustyList] = React.useState(false);

  const [showLowestScoringList,setShowLowestScoringList] = React.useState(false);
  const [showTaxLienList,setShowTaxLienList] = React.useState(false);
  const [noDataAvailableTaxLienList,setNoDataAvailableTaxLienList] = React.useState(false);

  const [showJudgementList,setShowJudgementList] = React.useState(false);
  const [noDataAvailableJudgementList,setNoDataAvailableJudgementList] = React.useState(false);

  const [showSuitsList,setShowSuitsList] = React.useState(false);
  const [noDataAvailableSuitsList,setNoDataAvailableSuitsList] = React.useState(false);

  const [showNewCautinaryUccList,setShowNewCautinaryUccList] = React.useState(false);
  const [noDataAvailableNewCautionaryUccList,setNoDataAvailableNewCautionaryUccList] = React.useState(false);

  const [showNewTaxLienList,setShowNewTaxLienList] = React.useState(false);
  const [noDataAvailableNewTaxLienList,setNoDataAvailableNewTaxLienList] = React.useState(false);

  const [showNewJudgementList,setShowNewJudgementList] = React.useState(false);
  const [noDataAvailableNewJudgementList,setNoDataAvailableNewJudgementList] = React.useState(false);

  const [showNewSuitsList,setShowNewSuitsList] = React.useState(false);
  const [noDataAvailableNewSuitsList,setNoDataAvailableNewSuitsList] = React.useState(false);

  const [showNoNewDerogFilings, setShowNoNewDerogFilings] = React.useState(false);
  const [showNewDerogFilingsCount, setShowNewDerogFilingsCount] = React.useState(false);

  const[propertyId,setPropertyId]=React.useState(location.state.propertyId);
  const[userId,setUserId]=React.useState(location.state.userId);
  const[organisationId,setOrganisationId]=React.useState(location.state.organisationId);
  const[propertyName,setPropertyName]=React.useState(location.state.propertyName);

  // const [imageUrl, setImageUrl] = React.useState(location.state.imageUrl);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const todayNow = moment().format("MMMM YYYY"); 
  var currentYear  = moment().format('YYYY');
  const[showArepLogo,setShowArepLogo]=React.useState(false);
  const[showCarterLogo,setShowCarterLogo]=React.useState(false);


  React.useEffect(() => {
  
    getMonthlyPropertyDetailsPdfReports(organisationId,propertyId,userId);
    console.log("..........bbbbbbbbbbbbbbbbb............")

    if(organisationId=="60f7cf3a09b25f474b336f8b"){
      setShowArepLogo(true);
      setShowCarterLogo(false);
     }else if(organisationId=="654b476a423c6401f20f16db"){
       setShowArepLogo(false);
       setShowCarterLogo(true);
     }
     
    setShowPieChart(true);
    setShowLineChart(true);
    setChartLabels(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.riskCounts?.labels);
    setChartData(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.riskCounts?.riskCounts);
  
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowRiskCountPercentage > 0){
      setShowLowRiskCountPercentage(true);
    }else{
      setShowLowRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.mediumLowRiskCountPercentage > 0){
      setShowMediumLowRiskCountPercentage(true);
    }else{
      setShowMediumLowRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.moderateHighRiskCountPercentage > 0){
      setShowModerateHighRiskCountPercentage(true);
    }else{
      setShowModerateHighRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.mediumHighRiskCountPercentage > 0){
      setShowMediumHighRiskCountPercentage(true);
    }else{
      setShowMediumHighRiskCountPercentage(false);
    }
  
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.highRiskCountPercentage > 0){
      setShowHighRiskCountPercentage(true);
    }else{
      setShowHighRiskCountPercentage(false);
    }
    
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingTenantsList!==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.topPerformingTenantsList!=[]){
      setShowTopPerformingTenantsList(true);
      setNoDataAvailableTopPerformingTenantsList(false);
    }
    
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingTenantsList===null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.topPerformingTenantsList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.topPerformingTenantsList==[ ]){
      setShowTopPerformingTenantsList(false);
      setNoDataAvailableTopPerformingTenantsList(true);
    }
    
    
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingIndustryList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.topPerformingIndustryList != [ ]){
      setShowTopPerformingIndustriesList(true);
      setNoDataAvailableTopPerformingIndustriesList(false);
    
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingIndustryList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.topPerformingIndustryList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.topPerformingIndustryList == [ ]){
      setShowTopPerformingIndustriesList(false);
      setNoDataAvailableTopPerformingIndustriesList(true);
    }
    //lowscoring tenants
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringTenantsList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.lowestScoringTenantsList != [ ]){
      setShowLowestScoringTenants(true);
      setNoDataAvailableLowestScoringTenants(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringTenantsList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.lowestScoringTenantsList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.lowestScoringTenantsList == [ ]){
      setShowLowestScoringTenants(false);
      setNoDataAvailableLowestScoringTenants(true);
    }

    //lowscoring industries
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringIndustriesList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.lowestScoringIndustriesList != [ ]){
      setShowLowestScoringIndustries(true);
      setNoDataAvailableLowestScoringIndustries(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringIndustriesList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.lowestScoringIndustriesList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.lowestScoringIndustriesList == [ ]){
      setShowLowestScoringIndustries(false);
      setNoDataAvailableLowestScoringIndustries(true);
    }

    //incremented credit score
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreIncrementedList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreIncrementedList != [ ]){
      setShowIncrementedCreditScoreList(true);
      setNoDataAvailableIncreasedCreditScoreList(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreIncrementedList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreIncrementedList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreIncrementedList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreIncrementedList == [ ]){
      setShowIncrementedCreditScoreList(false);
      setNoDataAvailableIncreasedCreditScoreList(true);
    }

    //decremented score list
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreDecrementedList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreDecrementedList != [ ]){
      setShowDecrementedCreditScoreList(true);
      setNoDataAvailableDecreasedCreditScoreList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreDecrementedList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreDecrementedList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreDecrementedList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.creditScoreDecrementedList == [ ]){
      setShowDecrementedCreditScoreList(false);
      setNoDataAvailableDecreasedCreditScoreList(true);
    }

    //positivenews list

    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.positiveNewsList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.positiveNewsList != [ ]){
      setShowPositiveAlertsTenantList(true);
      setNoDataAvailablePositiveAlertsTenantList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.positiveNewsList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.positiveNewsList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.positiveNewsList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.positiveNewsList == [ ]){
      setShowPositiveAlertsTenantList(false);
      setNoDataAvailablePositiveAlertsTenantList(true);
    }
    // negative news list
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.negativeNewsList!=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.negativeNewsList != [ ]){
      setShowNegativeAlertsTenantList(true);
      setNoDataAvailableNegativeAlertsTenantList(false);
      
    }
    if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.negativeNewsList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.negativeNewsList==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.negativeNewsList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.negativeNewsList == [ ]){
      setShowNegativeAlertsTenantList(false);
      setNoDataAvailableNegativeAlertsTenantList(true);
    }

//cautionaryUcclist
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.cautionaryUccListForPdfDTO != null  ){
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.cautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList
  !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.cautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList
  != [ ]){
  setShowCautinaryUccList(true);
  setNoDataAvailableCautionaryUccList(false);
}
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.cautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList
  ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.cautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.cautionaryUccListForPdfDTO.cautionaryUccAndTenantDTOList
  == [ ]){
  setShowCautinaryUccList(false);
  // setNoDataAvailableCautionaryUccList(true);
}
    
}

//taxlien
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.taxLienListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.taxLienListForPdfDTO?.taxLiensTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.taxLienListForPdfDTO?.taxLiensTenantDTOList
    != [ ]){
    setShowTaxLienList(true);
    setNoDataAvailableTaxLienList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.taxLienListForPdfDTO?.taxLiensTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.taxLienListForPdfDTO?.taxLiensTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.taxLienListForPdfDTO.taxLiensTenantDTOList
    == [ ]){
      setShowTaxLienList(false);
      // setNoDataAvailableTaxLienList(true);
  }
      
  }

  //judgement
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.judgementListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.judgementListForPdfDTO?.judgementTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.judgementListForPdfDTO?.judgementTenantDTOList
    != [ ]){
    setShowJudgementList(true);
    setNoDataAvailableJudgementList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.judgementListForPdfDTO?.judgementTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.judgementListForPdfDTO.judgementTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.judgementListForPdfDTO.judgementTenantDTOList
    == [ ]){
      setShowJudgementList(false);
      // setNoDataAvailableJudgementList(true);
  }
      
  }

   //SUits
if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.suitsListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.suitsListForPdfDTO?.suitsTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.suitsListForPdfDTO?.suitsTenantDTOList
    != [ ]){
    setShowSuitsList(true);
    setNoDataAvailableSuitsList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.suitsListForPdfDTO?.suitsTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.suitsListForPdfDTO?.suitsTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.suitsListForPdfDTO?.suitsTenantDTOList
    == [ ]){
      setShowSuitsList(false);
      // setNoDataAvailableSuitsList(true);
  }
      
  }

  if(generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.cautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList==null&& 
    generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.taxLienListForPdfDTO?.taxLiensTenantDTOList==null && 
    generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.judgementListForPdfDTO?.judgementTenantDTOListt==null && 
    generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.suitsListForPdfDTO?.suitsTenantDTOList==null){
    setShowSuitsList(false);
    setShowJudgementList(false);
    setShowTaxLienList(false);
    setShowCautinaryUccList(false);
  }else{
    // setShowNoNewDerogFilings(false);
    // setShowNewDerogFilingsCount(true);
  }

  //new cautionaryUcclist
if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO != null  ){
  if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList
    !=null || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList
    != [ ]){
    setShowNewCautinaryUccList(true);
    setNoDataAvailableNewCautionaryUccList(false);
  }
  if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList
    ==null || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList
    == [ ]){
    setShowNewCautinaryUccList(false);
    // setNoDataAvailableNewCautionaryUccList(true);
  }
      
  }
  
  //new taxlien
  if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO != null  ){
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO?.taxLiensTenantDTOList
      !=null || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO?.taxLiensTenantDTOList
      != [ ]){
      setShowNewTaxLienList(true);
      setNoDataAvailableNewTaxLienList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO?.taxLiensTenantDTOList
      ==null || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO?.taxLiensTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO?.taxLiensTenantDTOList
      == [ ]){
        setShowNewTaxLienList(false);
        // setNoDataAvailableNewTaxLienList(true);
    }
        
    }
  
    //new judgement
  if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO != null  ){
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO?.judgementTenantDTOList
      !=null || generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.newJudgementListForPdfDTO?.judgementTenantDTOList
      != [ ]){
      setShowNewJudgementList(true);
      setNoDataAvailableNewJudgementList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO?.judgementTenantDTOList
      ==null || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO?.judgementTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO?.judgementTenantDTOList
      == [ ]){
        setShowNewJudgementList(false);
        // setNoDataAvailableNewJudgementList(true);
    }
        
    }
  
     //new suits
  if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO != null  ){
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO?.suitsTenantDTOList
      !=null || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO?.suitsTenantDTOList
      != [ ]){
      setShowNewSuitsList(true);
      setNoDataAvailableNewSuitsList(false);
    }
    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO?.suitsTenantDTOList
      ==null || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO?.suitsTenantDTOList?.length===0 || generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO?.suitsTenantDTOList
      == [ ]){
        setShowNewSuitsList(false);
        // setNoDataAvailableNewSuitsList(true);
    }
        
    }

    if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList==null&& 
      generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO?.taxLiensTenantDTOList==null && 
      generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO?.judgementTenantDTOListt==null && 
      generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO?.suitsTenantDTOList==null){
      setShowNoNewDerogFilings(true);
      setShowNewDerogFilingsCount(false);
      setShowNewSuitsList(false);
      setShowNewJudgementList(false);
      setShowNewTaxLienList(false);
      setShowNewCautinaryUccList(false);
    }else{
      setShowNoNewDerogFilings(false);
      setShowNewDerogFilingsCount(true);
    }


      if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.recommendationDTO?.propertyNotificationCount===0){
        setShowPropertyNotification(false);
        setNoDataAvailablePropertyNotification(true);
      }else{
        setShowPropertyNotification(true);
        setNoDataAvailablePropertyNotification(false);
      }
      
    
      if(generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.recommendationDTO?.liensCount===0 && generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.recommendationDTO?.judgementCount===0 && generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.recommendationDTO?.suitsCount===0 && generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.recommendationDTO?.cautionaryUccCount===0){
        setShowDerogCount(false);
        setNoDataAvailableDerogCount(true);
      }else{
        setShowDerogCount(true);
        setNoDataAvailableDerogCount(false);
      }
    
      setTimeout(() => {
        setDataStatus(true);
      }, 500);
        }, [dataStatus,generateMonthlyPropertyDetailsPdfReport!=""]);
    
const getMonthlyPropertyDetailsPdfReports = async (organisationId,propertyId,userId) => {
  await tmGenerateMonthlyPropertyDetailsPdfReportRequest(organisationId,propertyId,userId);

  };
const ChartJsImage = require('chartjs-to-image');



const chartOptions = {
  options: {
    chart: {
      type: "pie",
    },
    labels: generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.riskCounts?.labels,
    legend: {
      position: "bottom",
    },
    colors: ['#4caf50', '#32cd32', '#ffdf00', '#ff9800', 'red'], // Define colors inside options
  },
  series: generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.riskCounts?.riskCounts, // ApexCharts expects data in series as an array
};

const handleClickDownload = (value) => {

  tmGenerateMonthlyPropertyDetailsPdfDownloadRequest(organisationId,propertyId,userId,propertyName)

};

if(dataStatus){
return(

<div>
  <Card>
  <div align="right" style={{marginRight: "35px",marginTop:"10px"}}>
    <Button onClick={handleClickDownload}  color="info" size="sm" className={classes.firstButton}  style={{fontSize:16, fontWeight: "bold"}}>
    <PrintIcon/>  &nbsp;&nbsp;Print  
    </Button>
  </div>
<div style={{fontSize:"25px",marginTop:"20px",color:megaColor,fontWeight:"bold",textAlign:"center"}}>Monthly {propertyName} Summary</div>
<div style={{fontSize:"25px",marginTop:"20px",color:megaColor,textAlign:"center"}}>{todayNow}</div>

<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}}>            
 <div class="table-container-tenant-summary">
 {propertyName} has an overall score of {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.propertyScore} (on a scale of 0-500).
 </div>
</div>

<div style={{display:"flex",flexDirection:"row"}}>
  <div class="graph-card">
     <Chart options={chartOptions.options} series={chartOptions.series} type="pie" width={280} height={290} align="center"/>
  </div>
  <div class="arrow">
  <img class="arrow2" src={bluerightarrow} alt="Arrow" />
  </div>
  <div class="graph-card1">
  <p style={{ fontSize: "15px", color: "#000000", marginTop: 5,fontWeight:"bold" }}>{propertyName} has:</p>

        {showLowRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 7 }}>
           <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowRiskCountPercentage}% of its tenants with a Low Risk Score (401 and higher)
            </p>  

            </div>
        )}

        {showMediumLowRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
           <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.mediumLowRiskCountPercentage}% of its tenants with a Medium Low Risk Score (301 to 400)
            </p>  

            </div>
        )}

        {showModerateHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
            <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.moderateHighRiskCountPercentage}% of its tenants with a Moderately High Risk Score (201 to 300)
            </p>  

            </div>
        )}

        {showMediumHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
             <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.mediumHighRiskCountPercentage}% of its tenants with a Medium High Risk Score (101 to 200)
            </p>  

            </div>
        )}
        {showHighRiskCountPercentage && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
              <img class="square-bullet" src={squareBullet} alt="Bullet" />
            <p style={{ fontSize: "13px", color: "#000000", marginLeft: 3,marginTop: 4 }}>
              {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.highRiskCountPercentage}% of its tenants with a very High Risk Score (0 to 100)
            </p>  

            </div>
        )}
</div>
</div>
<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Top Performing Entities</div>
</header>

{showTopPerformingTenantsList&&
<table class="table-income">
  <thead class="table-income-thead">
    <tr>
        <td >Monitored Entities </td>
        <td style={{textAlign:"center"}}>Monitored Entity Score</td>
        <td style={{textAlign:"center"}}>Property (%)</td>
        <td style={{textAlign:"center"}}>Monthly Change</td>
        
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingTenantsList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingTenantsList?.map((topPerformingTenant, i)=>(
    <tr>
      <td style={{whiteSpace:"nowrap"}}>{topPerformingTenant.name}</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingTenant.currentScore}</td>           
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingTenant.percentageOfPortfolio}%</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingTenant.monthlyChange==="up" ? <img class="monthly-arrow" src={arrowupgreen} alt="Arrow" /> : topPerformingTenant.monthlyChange==='down' ? <img class="monthly-arrow" src={arrowdownred} alt="Arrow" /> : <img class="monthly-arrow" src={arroworange} alt="Arrow" />}</td>
    </tr>
  ))}
  </tbody>
</table>}

{noDataAvailableTopPerformingTenantsList&&
<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>There are no tenants which have a score of more than 300 for the current month.</div>}
</div>

{/* Lowest Performing tenant */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Lowest Performing Entities</div>
</header>

{showLowestScoringTenants&&
<table class="table-income">
  <thead class="table-income-thead">
    <tr>
        <td >Monitored Entities </td>
        <td style={{textAlign:"center"}}>Monitored Entity Score</td>
        <td style={{textAlign:"center"}}>Property (%)</td>
        <td style={{textAlign:"center"}}>Monthly Change</td>
        
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringTenantsList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringTenantsList?.map((lowestScoringTenant, i)=>(
     <tr>
      <td style={{whiteSpace:"nowrap"}}>{lowestScoringTenant.name}</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{lowestScoringTenant.currentScore}</td>           
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{lowestScoringTenant.percentageOfPortfolio}%</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{lowestScoringTenant.monthlyChange==="up" ? <img class="monthly-arrow" src={arrowupgreen} alt="Arrow" />  :  lowestScoringTenant.monthlyChange==='down' ? <img class="monthly-arrow" src={arrowdownred} alt="Arrow" />  : <img class="monthly-arrow" src={arroworange} alt="Arrow" /> }</td>
    </tr>
  ))}
  </tbody>
</table>}

{noDataAvailableLowestScoringTenants&&
<div style={{fontSize:"16px",marginTop:"20px",fontWeight:900,textAlign:"center"}}>There are no tenants which have a score less than 300 for the current month.</div>}
</div>


{/* Top performing Industries */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Top Performing Industries</div>
</header>

{showTopPerformingIndustriesList&&
<table class="table-income">
  <thead class="table-income-thead">
    <tr>
      <td >Industries</td>
      <td style={{textAlign:"center"}}>Industry Score</td>
      <td style={{textAlign:"center"}}>Property (%)</td>
      <td style={{textAlign:"center"}}>Monthly Change</td>
        
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingIndustryList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.topPerformingIndustryList?.map((topPerformingIndustry,i)=>(
  <tr>
      <td style={{whiteSpace:"nowrap"}}>{topPerformingIndustry.name}</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingIndustry.currentScore}</td>           
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingIndustry.percentageOfPortfolio}%</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{topPerformingIndustry.monthlyChange==="up" ? <img class="monthly-arrow" src={arrowupgreen} alt="Arrow" />  : topPerformingIndustry.monthlyChange==='down' ? <img class="monthly-arrow" src={arrowdownred} alt="Arrow" />  : <img class="monthly-arrow" src={arroworange} alt="Arrow" /> }</td>
    </tr>
  ))}
  </tbody>
</table>}
{noDataAvailableTopPerformingIndustriesList&&
<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>No Data Available for top performing industries...</div>}
</div>

{/* Lowest Performing Industries */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Lowest Performing Industries</div>
</header>

{showLowestScoringIndustries&&
<table class="table-income">
  <thead class="table-income-thead">
    <tr>
      <td >Industries</td>
      <td style={{textAlign:"center"}}>Industry Score</td>
      <td style={{textAlign:"center"}}>Property (%)</td>
      <td style={{textAlign:"center"}}>Monthly Change</td>
        
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringIndustriesList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.lowestScoringIndustriesList?.map((lowestScoringIndustry,i)=>(
  <tr>
      <td style={{whiteSpace:"nowrap"}}>{lowestScoringIndustry.name}</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{lowestScoringIndustry.currentScore}</td>           
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{lowestScoringIndustry.percentageOfPortfolio}%</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{lowestScoringIndustry.monthlyChange==="up" ? <img class="monthly-arrow" src={arrowupgreen} alt="Arrow" />  : lowestScoringIndustry.monthlyChange==='down' ? <img class="monthly-arrow" src={arrowdownred} alt="Arrow" />  : <img class="monthly-arrow" src={arroworange} alt="Arrow" /> }</td>
    </tr>
  ))}
  </tbody>
</table>}

{noDataAvailableLowestScoringIndustries&&<div style={{fontSize:"16px",marginTop:"20px",fontWeight:900,textAlign:"center"}}>No Data Available for lowest scoring industries...</div>}
</div>

<div  class="section-content-title-tenantsummary" >
        Credit Score Changes
    </div>

   <div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">
 {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.increasedTenantCreditScoreCount} tenant(s) of the total {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.totalTenantCount} tenants in the property had increases of over 50 basis points, {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.decreasedTenantCreditScoreCount} tenants of the total {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.totalTenantCount} tenants in the property had decreases of over 50 basis points.</div>
</div>

{/* Entities with Increased Credit score */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Entities with Increased Credit Score</div>
</header>

{showIncrementedCreditScoreList&& 
<table class="table-income">
  <thead class="table-income-thead">
    <tr>
      <td style={{textAlign:"left"}}>Monitored Entities</td>
      <td style={{textAlign:"center"}}>Previous Score</td>
      <td style={{textAlign:"center"}}>Current Score</td>
        
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreIncrementedList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreIncrementedList?.map((increment, i)=>(
      <tr>
      <td style={{whiteSpace:"nowrap"}}>{increment.name}</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{increment.prevScore}</td>           
      <td style={{textAlign:"center"}}>{increment.currScore}&nbsp;({increment.percentage}%)</td>
    </tr>
  ))}
  </tbody>
</table>}
{noDataAvailableIncreasedCreditScoreList&&
<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>There are no tenants which have a credit score which increased by 50 basis points in the current month.</div>}
</div>

{/* Entities with Decreased Credit Score */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Entities with Decreased Credit Score</div>
</header>

{showDecrementedCreditScoreList&&
<table class="table-income">
  <thead class="table-income-thead">
    <tr>
      <td style={{textAlign:"left"}}>Monitored Entities</td>
      <td style={{textAlign:"center"}}>Previous Score</td>
      <td style={{textAlign:"center"}}>Current Score</td>
        
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreDecrementedList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreDecrementedList?.map((decrement, i)=>(
     <tr>
      <td style={{whiteSpace:"nowrap"}}>{decrement.name}</td>
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{decrement.prevScore}</td>           
      <td style={{whiteSpace:"nowrap",textAlign:"center"}}>{decrement.currScore}&nbsp;({decrement.percentage}%)</td>
    </tr>
  ))}
  </tbody>
</table>}
{noDataAvailableDecreasedCreditScoreList&&
<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>There are no tenants which have a credit score which decreased by 50 basis points in the current month.</div>}
</div>

<div  class="section-content-title-tenantsummary" >
Derogatory Summary
</div>
{showNoNewDerogFilings &&
<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}}>            
 <div class="table-container-tenant-summary">There are no new derogatory filings for the current month.    </div>
</div>}

{showNewDerogFilingsCount &&<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">{generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.creditScoreText}</div>
</div>}

{/* new Cautionary ucc filing */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showNewCautinaryUccList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Cautionary UCC Filings</div>
</header>}

{showNewCautinaryUccList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newCautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList?.map((cautionaryUccDetails, i)=>(
<div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {cautionaryUccDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Jurisdiction</td>
      <td>Secured Party Information</td>
      <td>Collateral</td>
        
    </tr>
  </thead>
  <tbody>
  {cautionaryUccDetails.cautionaryUccDetailsList.map((cautionaryUccDetails, i)=>(   <tr> 
      <td>{Moment(cautionaryUccDetails.filedDate).format('MM-DD-YYYY')}</td>
      <td>{cautionaryUccDetails.jurisdiction}</td>           
      <td>{cautionaryUccDetails.securedPartyName}%</td>
      <td>{cautionaryUccDetails.collateral}%</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableNewCautionaryUccList&&
<div style={{fontSize:"16px",marginTop:10,fontWeight:900,textAlign:"center"}}>No Data Available for Cautionary UCC Filings...</div>}
</div>


{/* new Tax lien filing */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showNewTaxLienList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">TaxLien Filings</div>
</header>}

{showNewTaxLienList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newTaxLienListForPdfDTO?.taxLiensTenantDTOList?.map((taxLienDetails, i)=>(
<div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {taxLienDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Filing Type</td>
      <td>Filing Number</td>
      <td>Amount</td>
      <td>Jurisdiction</td>
        
    </tr>
  </thead>
  <tbody>
  {taxLienDetails.taxLienDerogDetailsList!="" && taxLienDetails.taxLienDerogDetailsList.map((taxLien, i)=>(
   <tr> 
      <td>{Moment(taxLien.filedDate).format('MM-DD-YYYY')}</td>
      <td>{taxLien.filingType}</td>           
      <td>{taxLien.filingNumber}</td>
      <td>{taxLien.amount}</td>
      <td>{taxLien.jurisdiction}</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableNewTaxLienList&&
<div style={{fontSize:"16px",marginTop:10,fontWeight:900,textAlign:"center"}}>No Data Available for TaxLien Filings...</div>}
</div>

{/* new Judgement filings*/}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showNewJudgementList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Judgement Filings</div>
</header>}

{showNewJudgementList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newJudgementListForPdfDTO?.judgementTenantDTOList?.map((judgementDetails, i)=>(<div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {judgementDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Filing Type</td>
      <td>Filing Number</td>
      <td>Amount</td>
      <td>Plaintiff</td>
        
    </tr>
  </thead>
  <tbody>
  {judgementDetails.judgementDerogDetailsList!="" && judgementDetails.judgementDerogDetailsList.map((judgement, i)=>(   <tr> 
      <td>{Moment(judgement.filedDate).format('MM-DD-YYYY')}</td>
      <td>{judgement.filingType}</td>           
      <td>{judgement.filingNumber}</td>
      <td>{judgement.amount}</td>
      <td>{judgement.plaintiff}</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableNewJudgementList&&
<div style={{fontSize:"16px",marginTop:10,fontWeight:900,textAlign:"center"}}>No Data Available for Judgement Filings...</div>}
</div>

{/* new civil suits */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showNewSuitsList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Civil Suits</div>
</header>}

{showNewSuitsList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.newSuitsListForPdfDTO?.suitsTenantDTOList?.map((suitsDetails, i)=>(
<div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {suitsDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Filing Type</td>
      <td>Filing Number</td>
      <td>Amount</td>
      <td>Plaintiff</td>
        
    </tr>
  </thead>
  <tbody>
  {suitsDetails.suitsDerogDetailsList!="" && suitsDetails.suitsDerogDetailsList.map((suit, i)=>(    <tr> 
      <td>{Moment(suit.filedDate).format('MM-DD-YYYY')}</td>
      <td>{suit.filingType}</td>           
      <td>{suit.filingNumber}</td>
      <td>{suit.amount}</td>
      <td>{suit.plaintiff}</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableNewSuitsList&&
<div style={{fontSize:"16px",marginTop:10,marginBottom:20,fontWeight:900,textAlign:"center"}}>No Data Available for Civil Suits...</div>}
</div>

<div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">
 {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.derogText}
 </div>
 </div>


{/* Cautionary ucc filing */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showCautinaryUccList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Cautionary UCC Filings</div>
</header>}

{showCautinaryUccList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.cautionaryUccListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.cautionaryUccListForPdfDTO?.cautionaryUccAndTenantDTOList?.map((cautionaryUccDetails, i)=>(
<div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {cautionaryUccDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Jurisdiction</td>
      <td>Secured Party Information</td>
      <td>Collateral</td>
        
    </tr>
  </thead>
  <tbody>
  {cautionaryUccDetails.cautionaryUccDetailsList?.map((cautionaryUccDetails, i)=>(    <tr> 
      <td>{Moment(cautionaryUccDetails.filedDate).format('MM-DD-YYYY')}</td>
      <td>{cautionaryUccDetails.jurisdiction}</td>           
      <td>{cautionaryUccDetails.securedPartyName}%</td>
      <td>{cautionaryUccDetails.collateral}%</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableCautionaryUccList&&
<div style={{fontSize:"16px",marginTop:10,fontWeight:900,textAlign:"center"}}>No Data Available for Cautionary UCC Filings...</div>}
</div>

{/* Tax lien filing */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showTaxLienList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">TaxLien Filings</div>
</header>}

{showTaxLienList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.taxLienListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.taxLienListForPdfDTO?.taxLiensTenantDTOList?.map((taxLienDetails, i)=>(<div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {taxLienDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Filing Type</td>
      <td>Filing Number</td>
      <td>Amount</td>
      <td>Jurisdiction</td>
        
    </tr>
  </thead>
  <tbody>
  {taxLienDetails.taxLienDerogDetailsList!="" && taxLienDetails.taxLienDerogDetailsList.map((taxLien, i)=>(   <tr> 
      <td>{Moment(taxLien.filedDate).format('MM-DD-YYYY')}</td>
      <td>{taxLien.filingType}</td>           
      <td>{taxLien.filingNumber}</td>
      <td>{taxLien.amount}</td>
      <td>{taxLien.jurisdiction}</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableTaxLienList&&<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>No Data Available for TaxLien Filings...</div>}
</div>

{/*Judgement filings*/}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showJudgementList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Judgement Filings</div>
</header>}

{showJudgementList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.judgementListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.judgementListForPdfDTO?.judgementTenantDTOList?.map((judgementDetails, i)=>(  <div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {judgementDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Filing Type</td>
      <td>Filing Number</td>
      <td>Amount</td>
      <td>Plaintiff</td>
        
    </tr>
  </thead>
  <tbody>
  {judgementDetails.judgementDerogDetailsList!="" && judgementDetails.judgementDerogDetailsList.map((judgement, i)=>(    <tr> 
      <td>{Moment(judgement.filedDate).format('MM-DD-YYYY')}</td>
      <td>{judgement.filingType}</td>           
      <td>{judgement.filingNumber}</td>
      <td>{judgement.amount}</td>
      <td>{judgement.plaintiff}</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableJudgementList&&<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>No Data Available for Judgement Filings...</div>}
</div>

{/* civil suits */}
<div style={{marginLeft:"35px",marginRight:"35px"}}>
{showSuitsList&&<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Civil Suits</div>
</header>}

{showSuitsList&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.suitsListForPdfDTO!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.suitsListForPdfDTO?.suitsTenantDTOList?.map((suitsDetails, i)=>(
<div>
<div style={{fontSize:"15px",fontWeight:"bold",textAlign:"left",marginTop:"8px"}}>Entity: {suitsDetails.tenantName}</div>
<table class="table-income">
  <thead class="table-income-thead">
    <tr>    
      <td>Date Filed</td>
      <td>Filing Type</td>
      <td>Filing Number</td>
      <td>Amount</td>
      <td>Plaintiff</td>
        
    </tr>
  </thead>
  <tbody>
  {suitsDetails.suitsDerogDetailsList!="" && suitsDetails.suitsDerogDetailsList.map((suit, i)=>(     <tr> 
      <td>{Moment(suit.filedDate).format('MM-DD-YYYY')}</td>
      <td>{suit.filingType}</td>           
      <td>{suit.filingNumber}</td>
      <td>{suit.amount}</td>
      <td>{suit.plaintiff}</td>
    </tr>
  ))}
  </tbody>
</table>
</div>
))}
{noDataAvailableSuitsList&&<div style={{fontSize:"16px",marginTop:20,marginBottom:20,fontWeight:900,textAlign:"center"}}>No Data Available for Civil Suits...</div>}
</div>



<div  class="section-content-title-tenantsummary" >
       News Summary
    </div>

   <div style={{marginTop:"20px",marginLeft:"35px",marginRight:"35px"}} >            
 <div class="table-container-tenant-summary">
 {generateMonthlyPropertyDetailsPdfReport.propertyDetailsDTO?.newsText}    </div>
</div>

<div style={{marginLeft:"35px",marginRight:"35px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Entities with Positive News Alerts:</div>
</header>

{showPositiveAlertsTenantList&&
<table class="table-income">
  <thead class="table-income-thead">
    <tr>
      <td>Name</td>
      <td>Category</td>
        
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.positiveNewsList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.positiveNewsList?.map((positiveNews, i)=>(
    <tr>
      <td style={{whiteSpace:"nowrap"}}>{positiveNews.tenantName}</td>
      <td style={{whiteSpace:"nowrap"}}>{positiveNews.category.join(", ")}</td>           
    </tr>
  ))}
  </tbody>
</table>}
{noDataAvailablePositiveAlertsTenantList&&
<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>No Data Available for positive news alerts...</div>}
</div>

<div style={{marginLeft:"35px",marginRight:"35px",marginBottom:"30px"}}>
<header class="locationheader-tenantsummary">
        <div class="locationheadercolor-tenantsummary">Entities with Negative News Alerts:</div>
</header>

{showNegativeAlertsTenantList&&<table class="table-income">
  <thead class="table-income-thead">
    <tr>
      <td>Name</td>
      <td>Category</td>
    </tr>
  </thead>
  <tbody>
  {generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.negativeNewsList!=""&&generateMonthlyPropertyDetailsPdfReport?.propertyDetailsDTO?.negativeNewsList?.map((negativeNews, i)=>(
    <tr>
      <td style={{whiteSpace:"nowrap"}}>{negativeNews.tenantName}</td>
      <td style={{whiteSpace:"nowrap"}}>{negativeNews.category.join(", ")}</td>           
    </tr>
  ))}
  </tbody>
</table>}
{noDataAvailableNegativeAlertsTenantList&&<div style={{fontSize:"16px",marginTop:20,fontWeight:900,textAlign:"center"}}>No Data Available for negative news alerts...</div>}
</div>


  </Card>
</div>
   

  );
}else{
return(

<div align="center">
<Loader
    type="ThreeDots"
    color="#00BFFF"
    height={500}
    width={100}
    timeout={3000} //3 secs
  /></div>
  )
}
}

PropertyMonthlyPdfReport.propTypes = {

  loading: PropTypes.bool.isRequired,
  tmGenerateMonthlyPropertyDetailsPdfReportRequest: PropTypes.func.isRequired,
  tmGenerateMonthlyPropertyDetailsPdfDownloadRequest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  generateMonthlyPropertyDetailsPdfReport: state.tenantmonitoring.generateMonthlyPropertyDetailsPdfReport,
  });              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyMonthlyPdfReport);